import React, { useState } from "react";
import { Button, Modal, Form, Alert } from "react-bootstrap";
import { getDatabase, ref, push, set, get } from "firebase/database";
import toast from "react-hot-toast";
import moment from "moment";
import "moment/locale/de";

export function KrankheitEintragen({ FID, year, CurrentMitarbeiter }) {
  const db = getDatabase();
  const [show, setShow] = useState(false);
  const [userID, setUserID] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [attest, setAttest] = useState("ohne");
  const [error, setError] = useState("");

  const today = moment().format("YYYY-MM-DD");

  // 🆕 Funktion zum Umwandeln des Datumsformats in DE
  function formatDateToDE(date) {
    return moment(date, "YYYY-MM-DD").format("DD.MM.YYYY");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!userID || !startDate || !endDate) {
      setError("Bitte alle Felder ausfüllen.");
      return;
    }

    if (moment(endDate).isBefore(moment(startDate))) {
      setError("Das Enddatum darf nicht vor dem Startdatum liegen.");
      return;
    }

    const anzahltage = moment(endDate).diff(moment(startDate), "days") + 1;

    const krankheitID = `krank_${startDate.replace(/-/g, "")}`;
    const krankRef = ref(db, `kalender/${FID}/${year}/${userID}/${krankheitID}`);

    const neueKrankheit = {
      anlass: "Krankheit",
      startDate: formatDateToDE(startDate), // 🆕 Deutsches Datumsformat
      endDate: formatDateToDE(endDate), // 🆕 Deutsches Datumsformat
      anzahltage,
      attest,
      erstelltAm: moment().format("DD.MM.YYYY HH:mm:ss"), // 🆕 Zeitstempel in DE Format
    };

    await set(krankRef, neueKrankheit);

    // 🚀 Aktualisiere krankheitstage in `/urlaube/{FID}/{year}/{userID}/`
    const urlaubsÜbersichtRef = ref(db, `urlaube/${FID}/${year}/${userID}`);
    const snapshot = await get(urlaubsÜbersichtRef);

    if (snapshot.exists()) {
      const userData = snapshot.val();
      const neueKrankheitstage = (userData.krankheitstage || 0) + anzahltage;

      await set(urlaubsÜbersichtRef, {
        ...userData,
        krankheitstage: neueKrankheitstage,
      });
    }

    setShow(false);
    toast.success(`Krankheitstage erfolgreich eingetragen! (${anzahltage} Tage)`);
  };

  return (
    <>
      <Button variant="danger" onClick={() => setShow(true)}>
        Krankheit eintragen
      </Button>

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Krankheit eintragen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}

          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Mitarbeiter</Form.Label>
              <Form.Control as="select" value={userID} onChange={(e) => setUserID(e.target.value)} required>
                <option value="">Bitte auswählen</option>
                {Object.keys(CurrentMitarbeiter).map((id) => (
                  <option key={id} value={id}>
                    {CurrentMitarbeiter[id].name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Startdatum</Form.Label>
              <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} required />
            </Form.Group>

            <Form.Group>
              <Form.Label>Enddatum</Form.Label>
              <Form.Control type="date" value={endDate} min={startDate || today} onChange={(e) => setEndDate(e.target.value)} required />
            </Form.Group>

            <Form.Group>
              <Form.Label>Attest</Form.Label>
              <Form.Control as="select" value={attest} onChange={(e) => setAttest(e.target.value)}>
                <option value="ohne">Ohne Attest</option>
                <option value="mit">Mit Attest</option>
              </Form.Control>
            </Form.Group>

            <Button variant="success" type="submit" className="mt-3">
              Krankheit eintragen
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
