import React from "react";
import { Table, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

function ExpiryDatesTab({ expiryData, handleDateChange }) {
  // Style für DatePicker-Felder: genügend Platz für "dd.mm.yyyy" + X
  const datePickerStyle = {
    minWidth: "120px",
    paddingRight: "2rem", // Abstand für das Clear-Icon
  };

  // Style für Spaltenüberschriften (z. B. min-width + kein Umbruch)
  const thStyle = {
    minWidth: "100px",
    whiteSpace: "nowrap",
  };

  // Style für Tabellenzellen, damit sie nicht automatisch schrumpfen
  const tdStyle = {
    minWidth: "130px", 
    whiteSpace: "nowrap",
  };

  return (
    <div className="table-responsive">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={thStyle}>Feldname</th>
            <th style={thStyle}>Ablaufdatum</th>
            <th style={thStyle}>Termin</th>
            <th style={thStyle}>Intervall</th>
            <th style={thStyle}>Termin gesetzt</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(expiryData).map(([key, value]) => {
            return (
              <tr key={key}>
                {/* Spalte 1: Feldname */}
                <td style={tdStyle}>
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </td>

                {/* Spalte 2: Ablaufdatum */}
                <td style={tdStyle}>
                  {key === "geburtstag" || key === "firmeneintritt" ? (
                    <DatePicker
                      className="w-100"
                      style={datePickerStyle}
                      locale="de"
                      selected={
                        moment(value).isValid()
                          ? moment(value).toDate()
                          : null
                      }
                      onChange={(date) =>
                        handleDateChange(key, moment(date).valueOf())
                      }
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      dateFormat="dd.MM.yyyy"
                      dropdownMode="select"
                      isClearable
                      minDate={moment("1900-01-01").toDate()}
                      placeholderText="Datum wählen"
                    />
                  ) : (
                    <DatePicker
                      className="w-100"
                      style={datePickerStyle}
                      locale="de"
                      selected={value.expiryDate}
                      onChange={(date) => {
                        if (date === null) date = new Date(0);
                        handleDateChange(key, date.getTime(), "expiryDate");
                      }}
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      dateFormat="dd.MM.yyyy"
                      dropdownMode="select"
                      isClearable
                      minDate={moment("1900-01-01").toDate()}
                      placeholderText="Datum wählen"
                    />
                  )}
                </td>

                {/* Restliche Felder nur, wenn key != 'geburtstag'/'firmeneintritt' */}
                {key !== "geburtstag" && key !== "firmeneintritt" && (
                  <>
                    <td style={tdStyle}>
                      {value.actualAppointmentDate !== undefined && (
                        <DatePicker
                          className="w-100"
                          style={datePickerStyle}
                          locale="de"
                          selected={value.actualAppointmentDate}
                          onChange={(date) => {
                            if (date === null) date = new Date(0);
                            handleDateChange(
                              key,
                              date.getTime(),
                              "actualAppointmentDate"
                            );
                          }}
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          dateFormat="dd.MM.yyyy"
                          dropdownMode="select"
                          isClearable
                          minDate={moment("1900-01-01").toDate()}
                          placeholderText="Termin wählen"
                        />
                      )}
                    </td>

                    <td style={tdStyle}>
                      {value.duedateinterval !== undefined && (
                        <Form.Control
                          type="number"
                          value={value.duedateinterval}
                          onChange={(e) =>
                            handleDateChange(
                              key,
                              parseInt(e.target.value, 10),
                              "duedateinterval"
                            )
                          }
                          placeholder="Intervall in Tagen"
                          style={{ minWidth: "70px" }}
                        />
                      )}
                    </td>

                    <td style={tdStyle}>
                      {value.appointmentMade !== undefined && (
                        <Form.Check
                          type="checkbox"
                          checked={value.appointmentMade}
                          onChange={(e) =>
                            handleDateChange(
                              key,
                              e.target.checked,
                              "appointmentMade"
                            )
                          }
                          label="Ja"
                        />
                      )}
                    </td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default ExpiryDatesTab;
