import React, { useState } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { MenuItems } from "../components/MenuItems";
import LoginButton from "./pages/SignUp/LoginButton";
import Notice from "./pages/Notice/noticebutton";

import { useAuth } from "../context/authContext";
import { Link } from "react-router-dom";

export const MyNavbar = () => {
  const { currentFirma, isAdmin } = useAuth();
 
  const [expanded, setExpanded] = useState(false);
  const [Verwaltungitems] = useState(
    MenuItems.filter((x) => x.for === "Verwaltung")
  );
  const [erweiterteFunktionen] = useState(
    MenuItems.filter((x) => x.for === "erweiterte Funktionen")
  );
  const [einstellungen] = useState(
    MenuItems.filter((x) => x.for === "Einstellung")
  );

  return (
    <>
      <Navbar expanded={expanded} expand="lg" bg="dark" variant="dark">
        <Container fluid>
          <Navbar.Brand style={{ fontSize: 22 }}>
            {currentFirma && <div>{currentFirma.firmenname}</div>}
          </Navbar.Brand>
          <Navbar.Toggle
            onClick={() => setExpanded(expanded ? false : "expanded")}
            aria-controls="responsive-navbar-nav"
          />

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown title="Verwaltung" id="navbarScrollingDropdown">
                {Verwaltungitems.map((item, index) => {
                  if (isAdmin || !item.admin) {
                    if (item.divider === true)
                      return <NavDropdown.Divider key={index} />;
                    return (
                      <NavDropdown.Item
                        onClick={() => setExpanded(false)}
                        key={index}
                        as={Link}
                        to={item.path}
                      >
                        {item.fas} {item.title}
                      </NavDropdown.Item>
                    );
                  }
                  return null;
                })}
              </NavDropdown>
              {isAdmin ?
              
                <>
                  <NavDropdown
                    title="erweiterte Funktionen"
                    id="navbarDropdownAdmin"
                  >
                    {erweiterteFunktionen.map((item, index) => {
                      if (item.divider === true)
                        return <NavDropdown.Divider key={index} />;
                      return (
                        <NavDropdown.Item
                          onClick={() => setExpanded(false)}
                          key={index}
                          as={Link}
                          to={item.path}
                        >
                          {item.fas} {item.title}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>

                  <NavDropdown
                    title="Einstellungen"
                    id="navbarDropdownAdmin"
                  >
                    {einstellungen.map((item, index) => {
                      if (item.divider === true)
                        return <NavDropdown.Divider key={index} />;
                      return (
                        <NavDropdown.Item
                          onClick={() => setExpanded(false)}
                          key={index}
                          as={Link}
                          to={item.path}
                        >
                          {item.fas} {item.title}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>


                </>
              :<></>}
            </Nav>
          
            <div className="gap-4 d-flex justify-content-between">
              <Notice />             
              <LoginButton />
            </div>
           
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
