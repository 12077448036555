import { useState, useEffect } from "react";
import { ref, getDatabase, onValue } from "firebase/database";
import { useAuth } from "../../../context/authContext";
import moment from "moment";

const useLoadStopps = (selectedDate, userID = null) => {
  const { FID } = useAuth();
  const db = getDatabase();
  const [stopps, setStopps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("Lade Stopps für Firma", FID, "am", selectedDate);
    if (!FID || !selectedDate) return;
    const formattedDate = moment.utc(selectedDate).startOf("day").valueOf();
    //const formattedDate = moment(selectedDate).startOf("day").valueOf();
    console.log("Formatted Date:", formattedDate);
    const stoppsRef = ref(db, `dailybusiness/${FID}/${formattedDate}/stopps`);

    setLoading(true);

    onValue(
      stoppsRef,
      (snapshot) => {
        if (snapshot.exists()) {
          let data = snapshot.val();
          let stoppsList = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));

          // Falls eine Benutzer-ID angegeben ist, filtere nach diesem Benutzer
          if (userID) {
            stoppsList = stoppsList.filter((stopp) => stopp.userid === userID);
          }

          setStopps(stoppsList);
        } else {
          setStopps([]);
        }
        setLoading(false);
      },
      (error) => {
        setError(error.message);
        setLoading(false);
      }
    );

    return () => setStopps([]); // Cleanup bei Komponentenwechsel
  }, [FID, selectedDate, userID, db]);

  return { stopps, loading, error };
};

export default useLoadStopps;
