import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruckRampBox } from "@fortawesome/free-solid-svg-icons";
import "../../../styles/maptooltip.css";

const StopMarker = ({ data, tour }) => {
 
  const [showInfo, setShowInfo] = useState(false);
  const truckRanpBox = <FontAwesomeIcon icon={faTruckRampBox} />;

  const handleClick = (e) => {
    e?.preventDefault();
    setShowInfo(!showInfo);
  };

  const getStatusClass = (art) => {
    switch (art) {
      case "erfolgreich":
        return "gruen";
      case "vergeblich":
        return "rot";
      case "abholer":
        return "stellblue";
      default:
        return "grau";
    }
  };


  const formatDate = (timestamp) => {
    if (!timestamp) return "Unbekanntes Datum";
    return new Intl.DateTimeFormat("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(new Date(timestamp));
  };
  
  const formatDistance = (distance) => (distance ? (distance / 1000).toFixed(2) + " km" : "0 km");
  
  const formatElapsedTime = (seconds) => {
    if (!seconds) return "00:00:00";
    const h = Math.floor(seconds / 3600).toString().padStart(2, "0");
    const m = Math.floor((seconds % 3600) / 60).toString().padStart(2, "0");
    const s = (seconds % 60).toString().padStart(2, "0");
    return `${h}:${m}:${s}`;
  };
  const formatTime = (timestamp) => {
    if (!timestamp) return "Keine Uhrzeit";
  
    return new Intl.DateTimeFormat("de-DE", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(new Date(timestamp));
  };
  
  

  return (
    <div className="marker-container">
      <div className="marker" onClick={handleClick}>
        <span className={getStatusClass(data?.art)}>
          <b>
            <div>{tour?.bezeichnung || "Unbekannte Tour"}</div>
            <div className="text-center">{truckRanpBox}</div>
          </b>
        </span>
      </div>

      {showInfo && (
        <div className="info-box">
          <button className="close-btn" onClick={() => setShowInfo(false)}>
            ×
          </button>
          <h4>Stopp-Details</h4>

          <p>
            <strong>Adresse:</strong> {data?.adress || "Keine Adresse"}
          </p>
          <p>
            <strong>Status:</strong> {data?.art || "Unbekannt"}
          </p>
          <p>
          <p><strong>Erstellt um:</strong> {formatTime(data?.createdatetime)}</p>
          </p>
          <p>
            <strong>Distanz:</strong> {formatDistance(data?.distance)}
          </p>
          <p>
            <strong>Verstrichene Zeit:</strong>{" "}
            {formatElapsedTime(data?.elapsedTime)}
          </p>
        </div>
      )}
    </div>
  );
};

export default StopMarker;
