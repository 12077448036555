import React, { useState, useEffect, useMemo } from "react";
import GoogleMapReact from "google-map-react";
import { ref, get, child, getDatabase, onValue } from "firebase/database";
import { useAuth } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";

import UserMarker from "../live/UserMarker";
import StopMarker from "../live/StopMarker";

import "../live/styles.css";
import { Cube } from "react-preloaders2";
import isEmpty from "lodash.isempty";

const LiveMap = ({ center = { lat: 53.498985, lng: 9.974782 }, zoom = 15 }) => {
  const { isAdmin, FID } = useAuth();
  const navigate = useNavigate();
  const db = getDatabase();

  // 📌 States für Daten
  const [stoppsdata, setStoppsData] = useState([]);
  const [userPosdata, setUserPosData] = useState([]);
  const [touren, setTouren] = useState([]);
  const [stopps, setStopps] = useState([]);
  const [sensoren, setSensoren] = useState([]);
  const [userPos, setUserPos] = useState([]);
  const [userAnwesend, setUserAnwesend] = useState([]);
  const [dataLoad, setDataLoad] = useState(true);

  const memoizedStoppsData = useMemo(() => {
    if (isEmpty(stopps) || isEmpty(touren)) return [];
  
    return Object.entries(stopps).map(([key, value]) => ({
      key,
      lat: value.latLng.latitude,
      lng: value.latLng.longitude,
      data: value,
      tour: touren.find((x) => x.id === value.tourid),
    }));
  }, [stopps, touren]);

  // 📌 Berechnung des aktuellen Tages in Millisekunden
  const milltoDay = new Date().setUTCHours(0, 0, 0, 0);

  // 🚨 Falls nicht Admin → Weiterleitung zum Dashboard
  useEffect(() => {
    if (!isAdmin) {
      navigate("/Dashbord", { replace: true });
    }
  }, [isAdmin, navigate]);

  // 📌 Touren laden
  useEffect(() => {
    if (FID) {
      get(child(ref(db), `touren/${FID}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            const activeTours = Object.keys(data)
              .map((k) => ({ id: k, ...data[k] }))
              .filter((tour) => tour.isAktiv === true);

            setTouren(activeTours);
          }
        })
        .catch((error) =>
          console.error("Fehler beim Laden der Touren:", error)
        );
    }
  }, [FID, db]);

  // 📌 Echtzeitlistener für Stopps, Anwesenheit, Bewegung & Positionen
 // Anwesenheit Listener
useEffect(() => {
  if (!FID) return;

  const anwesenheitRef = ref(db, `dailybusiness/${FID}/${milltoDay}/anwesenheit`);
  const unsubscribe = onValue(anwesenheitRef, (snapshot) => {
 //   console.log("Anwesend->",snapshot.val())
    setUserAnwesend(snapshot.exists() ? snapshot.val() : []);
  });

  return () => unsubscribe(); // Cleanup beim Unmounten
}, [FID, db, milltoDay]);

// Stopps Listener
useEffect(() => {
  if (!FID) return;

  const stoppsRef = ref(db, `dailybusiness/${FID}/${milltoDay}/stopps`);
  const unsubscribe = onValue(stoppsRef, (snapshot) => {
  //  console.log("Stopps->",snapshot.val())
    setStopps(snapshot.exists() ? snapshot.val() : []);
  });

  return () => unsubscribe();
}, [FID, db, milltoDay]);

// Positionen Listener
useEffect(() => {
  if (!FID) return;

  const posRef = ref(db, `currentPos/${FID}/${milltoDay}`);
  const unsubscribe = onValue(posRef, (snapshot) => {
    //console.log("Pos->",snapshot.val())
    setUserPos(snapshot.exists() ? snapshot.val() : []);
  });

  return () => unsubscribe();
}, [FID, db, milltoDay]);

// Sensoren Listener (Bewegung & Signal)
useEffect(() => {
  if (!FID) return;

  const sensorenRef = ref(db, `movement/${FID}/${milltoDay}`);
  const unsubscribe = onValue(sensorenRef, (snapshot) => {
   
    setSensoren(snapshot.exists() ? snapshot.val() : []);
  });

  return () => unsubscribe();
}, [FID, db, milltoDay]);

// Setzt `dataLoad` nach Abschluss aller Listener
useEffect(() => {
  if (userAnwesend.length && stopps.length && userPos.length && sensoren.length) {
    setDataLoad(false);
  }
}, [userAnwesend, stopps, userPos, sensoren]);


  // 📌 Stopps-Daten aufbereiten
  useEffect(() => {
    if (!isEmpty(stopps) && !isEmpty(touren)) {
      setStoppsData((prevStopps) => {
        if (JSON.stringify(prevStopps) === JSON.stringify(stopps)) {
          return prevStopps; // Falls sich `stopps` nicht geändert hat, beibehalten
        }
        return Object.entries(stopps).map(([key, value]) => ({
          key,
          lat: value.latLng.latitude,
          lng: value.latLng.longitude,
          text: value.text || "🚛",
          data: value,
          tour: touren.find((x) => x.id === value.tourid),
        }));
      });
  
      setDataLoad(false);
    }
  }, [stopps, touren]);
  

  // 📌 User-Positionen aufbereiten
  useEffect(() => {
    if (!isEmpty(userPos)) {
      let count = 0;
      setUserPosData([]);

      Object.entries(userPos).forEach(([key, value]) => {
        try {
          const userAn = userAnwesend[key] || null;
          if (userAn) {
           
            
            setUserPosData((prev) => [
              ...prev,
              {
                key,
                lat: value.you.l[0],
                lng: value.you.l[1],
                data: userAn,
                text: ++count,
                movement: sensoren[key]?.movement || false,
                signal: sensoren[key]?.signal || 0,
              },
            ]);
           
          }
        } catch (error) {
          console.error("Fehler bei der User-Position:", error);
        }
      });

      setDataLoad(false);
    }
  }, [userAnwesend, userPos, sensoren]);

  // 📌 Map-Funktionen
  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();
    places.forEach((place) =>
      bounds.extend(new maps.LatLng(place.lat, place.lng))
    );
    return bounds;
  };

  const bindResizeListener = (map, maps, bounds) => {
    window.addEventListener("resize", () => {
      map.fitBounds(bounds);
    });
  };

  const handleApiLoaded = (map, maps) => {
    const bounds = getMapBounds(map, maps, userPosdata);
    map.fitBounds(bounds);
    bindResizeListener(map, maps, bounds);
  };

  return (
    <>
      {!isEmpty(userPosdata) || !isEmpty(stoppsdata) ? (
        <div style={{ height: "100vh", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
            defaultCenter={center}
            defaultZoom={zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          >
            {!isEmpty(userPosdata) &&
              userPosdata.map(({ lat, lng, key, data,movement,signal }) => (
                <UserMarker
                  key={key}
                  uid={key}
                  lat={lat}
                  lng={lng}
                  data={data}
                  movement={movement}
                  signal={signal}
                />
              ))}

            {!isEmpty(memoizedStoppsData) &&
              memoizedStoppsData.map(({ lat, lng, key, data, tour }) => (
                <StopMarker
                  key={key}
                  uid={key}
                  lat={lat}
                  lng={lng}
                  data={data}
                  tour={tour}
                />
              ))}
          </GoogleMapReact>
        </div>
      ) : (
        <Cube customLoading={dataLoad} time={0} background="#40bfb2" />
      )}
    </>
  );
};

export default LiveMap;
