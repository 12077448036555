import React from 'react';
//import ReactDOM from 'react-dom';
import * as ReactDOMClient from 'react-dom/client';
import App from './App';
import { AuthProvider }from "../src/context/authContext"
import "./components/pages/spesen/custom.css";

const container = document.getElementById('root');
const root =  ReactDOMClient.createRoot(container);

root.render(
        <AuthProvider>      
            <App />
        </AuthProvider>
);

