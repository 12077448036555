import { useState, useEffect } from "react";
import { ref, onValue, getDatabase } from "firebase/database";

const usePalettenKontoData = (fid) => {
  const [summaries, setSummaries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!fid) return; // Warte, bis FID da ist

    const db = getDatabase();
    // Wir lesen ALLE Einträge unter palettenkonto/<FID> (falls du das so strukturiert hast)
    const palettenRef = ref(db, `palettenkonto/`);
    setIsLoading(true);

    const unsubscribe = onValue(
      palettenRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const newSummaries = [];

          // data könnte sein: { "2JhPC9sCmDNVsX6b6uFUPNja6l93": {...}, "841": {...}, ... }
          Object.entries(data).forEach(([key, value]) => {
            let totalTagessaldoFP = 0;
            let totalTagessaldoGP = 0;
            let totalLeergutAbholungFP = 0;
            let totalLeergutAbholungGP = 0;

            // value enthält die Tagesdaten; summiere sie
            Object.values(value).forEach((day) => {
              totalTagessaldoFP += day?.tagessaldo?.fp || 0;
              totalTagessaldoGP += day?.tagessaldo?.gp || 0;
              totalLeergutAbholungFP += day?.leergutabholung?.fp || 0;
              totalLeergutAbholungGP += day?.leergutabholung?.gp || 0;
            });

            newSummaries.push({
              name: key,
              totalTagessaldoFP,
              totalTagessaldoGP,
              totalLeergutAbholungFP,
              totalLeergutAbholungGP,
              diffFP: totalTagessaldoFP - totalLeergutAbholungFP,
              diffGP: totalTagessaldoGP - totalLeergutAbholungGP,
            });
          });

          setSummaries(newSummaries);
        } else {
          setSummaries([]);
          setError("Keine Daten gefunden.");
        }
        setIsLoading(false);
      },
      (err) => {
        setError(err.message);
        setIsLoading(false);
      }
    );

    return () => unsubscribe();
  }, [fid]);

  return { summaries, isLoading, error };
};

export default usePalettenKontoData;
