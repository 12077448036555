import React, { useState, useEffect } from "react";
import { useAuth } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";
import isEmpty from "lodash.isempty";
import { Spinner, Card, Row, Col, Table, DropdownButton, Dropdown } from 'react-bootstrap';
import { getDatabase, ref, set } from "firebase/database"; // Realtime Database Funktionen
import { utcToZonedTime } from "date-fns-tz";

const Anwesheitclickday = ({ currentEvent , day }) => {
  const { currentUser, isAdmin } = useAuth();
  const [FID, setFID] = useState("");
  const navigate = useNavigate();
  const [data, SetMyData] = useState({}); // Set initial state as an empty object
  const [userid, SetMyUserID] = useState({}); //
  
  const timeZone = "Europe/Berlin";
  const millesek = utcToZonedTime(day, timeZone).setHours(1, 0, 0, 0);
  const [spesen, setSpesen] = useState(false); // State für die Spesenänderung

  useEffect(() => {
    if (currentUser) {
      setFID(currentUser.photoURL);
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (!isAdmin) {
      navigate("/Dashbord", { replace: true });
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    if (currentEvent) {
      const eventKey = Object.keys(currentEvent)[0];
      SetMyUserID(eventKey);
      SetMyData(currentEvent[eventKey]);
      setSpesen(currentEvent[eventKey]?.spesen || false); // Setze den initialen Wert für Spesen
    }
  }, [currentEvent]);

  const handleSpesenChange = async (value) => {
    setSpesen(value); // Setze den neuen Wert für Spesen
    if (data.Fahrzeug?.ID) {
      try {
        // Firebase Realtime Database: Referenz zur Datenbank
        const db = getDatabase(); // Firebase Realtime Database-Instanz
        const eventRef = ref(db, "dailybusiness/" + FID + "/" + millesek + "/anwesenheit/" + userid);
        
        
        // Spesen in der Realtime Database aktualisieren
        await set(eventRef, {
          ...data,  // Behalte die anderen Daten bei
          spesen: value, // Spesen aktualisieren
        });
        
        console.log("Spesen erfolgreich aktualisiert");
      } catch (error) {
        console.error("Fehler beim Aktualisieren der Spesen:", error);
      }
    }
  };

  if (isEmpty(data)) {
    return <Spinner animation="border" variant="primary" />;
  }

  return (
    <div style={{ maxHeight: "80vh", overflowY: "auto" }}>
      <h1 className="mb-4">Details über den Tag</h1>
      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Fahrzeug Information</Card.Title>
          <Row>
            <Col><strong>Fahrzeug ID:</strong> {data.Fahrzeug?.ID || "Nicht verfügbar"}</Col>
            <Col><strong>Kennzeichen:</strong> {data.Fahrzeug?.Kennzeichen || "Nicht verfügbar"}</Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Spesen</Card.Title>
          <Row>
            <Col>
              <DropdownButton
                id="dropdown-spesen"
                title={spesen ? "Ja" : "Nein"}
                onSelect={(selectedValue) => handleSpesenChange(selectedValue === "Ja")}
              >
                <Dropdown.Item eventKey="Ja">Ja</Dropdown.Item>
                <Dropdown.Item eventKey="Nein">Nein</Dropdown.Item>
              </DropdownButton>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Paletten</Card.Title>
          <Row>
            <Col><strong>Geladene Paletten (FP):</strong> {data.paletten?.FP?.geladen || "Nicht verfügbar"}</Col>
            <Col><strong>Entladene Paletten (FP):</strong> {data.paletten?.FP?.entladen || "Nicht verfügbar"}</Col>
          </Row>
          <Row>
            <Col><strong>Geladene Gitterboxen (GP):</strong> {data.paletten?.GP?.geladen || "Nicht verfügbar"}</Col>
            <Col><strong>Entladene Gitterboxen (GP):</strong> {data.paletten?.GP?.entladen || "Nicht verfügbar"}</Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Bewegung</Card.Title>
          <Row>
            <Col><strong>Bewegung:</strong> {data.movement?.movement ? 'Ja' : 'Nein'}</Col>
            <Col><strong>Sensor Status:</strong> {data.movement?.sensor || "Nicht verfügbar"}</Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Tour</Card.Title>
          <div><strong>Tour:</strong> {data.Tour || "Nicht verfügbar"}</div>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Orte</Card.Title>
          <h5>Feierabend Zone</h5>
          {data.places?.["Feierabend Zone"] ? (
            <Table bordered hover size="sm">
              <thead>
                <tr>
                  <th>Zeit</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(data.places["Feierabend Zone"]).map(([time, status]) => (
                  <tr key={time}>
                    <td>{new Date(parseInt(time)).toLocaleString()}</td>
                    <td>{status}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div>Keine Daten für die Feierabend Zone</div>
          )}

          <h5>Lade-Entlade Punkt</h5>
          {data.places?.["Lade-Entlade Punkt"] ? (
            <Table bordered hover size="sm">
              <thead>
                <tr>
                  <th>Zeit</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(data.places["Lade-Entlade Punkt"]).map(([time, status]) => (
                  <tr key={time}>
                    <td>{new Date(parseInt(time)).toLocaleString()}</td>
                    <td>{status}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div>Keine Daten für den Lade-Entlade Punkt</div>
          )}
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Sonstiges</Card.Title>
          <Row>
            <Col><strong>SLS:</strong> {data.SLS || "Nicht verfügbar"}</Col>
            <Col><strong>Tankstelle erreicht:</strong> {data.Tankstelle ? 'Ja' : 'Nein'}</Col>
          </Row>
          <Row>
            <Col><strong>Spesen:</strong> {data.spesen ? 'Ja' : 'Nein'}</Col>
            <Col><strong>Tagesabschluss:</strong> {data.tagesabschluss ? 'Ja' : 'Nein'}</Col>
          </Row>
          <Row>
            <Col><strong>Zone Feierabend:</strong> {data.zonefeierabend ? 'Ja' : 'Nein'}</Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Anwesheitclickday;
