
import React, { useState, useEffect} from "react";
import '../../../App.css';
import {  
    Card,  
    Button,  
    Row,
    Col,
  } from "react-bootstrap";
  import BootstrapTable from 'react-bootstrap-table-next';
  import paginationFactory from 'react-bootstrap-table2-paginator';
  import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
  import filterFactory, { textFilter ,selectFilter,dateFilter  } from 'react-bootstrap-table2-filter';
  import cellEditFactory , { Type }  from 'react-bootstrap-table2-editor';
  import useWindowDimensions from "../../useMediaQuery";
  import { ref, getDatabase, update,get,child, remove } from "firebase/database";
  import {useAuth}from "../../../context/authContext"  
  import { confirmAlert } from 'react-confirm-alert'; // Import
  import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css 
  
  import "bootstrap-icons/font/bootstrap-icons.css";
  

export const StoppsAuswahl = ({ childToParent, setShowStoppAuswahl, stoppauswahl }) => {
   
    const db = getDatabase(); 

    const delsign = <i className="bi bi-trash"></i>;

    const [loading, setLoading] = useState(true);
    const [Stopps,setStopps] = useState({});
    const [verguettung, setVerguettung] = useState([]);
    const [loadingUser, setLoadingUser] = useState(true);   
    const [SortUser,setSortUser] = useState({});
    
    const [loadingTour, setLoadingTour] = useState(true);    
    const [SortTour,setSortTour] = useState({});

    const [loadingFahrzeug, setLoadingFahrzeug] = useState(true);    
    const [SortFahrzeug,setSortFahrzeug] = useState({});

    const dbRef = ref(getDatabase());
    const [FID, setFID] = useState('');
    const {currentUser} = useAuth();   
    const [columns , setcolumns] = useState([]);
    const [rowCount , setRowCount] = useState(0);  
    const [gage , setGage] = useState([]); 
    const [gagegesamt , setGageGesamt] = useState([]); 
    const { width } = useWindowDimensions();
    
 
    const formatDate = (timestamp ) => {
        return new Intl.DateTimeFormat('de-DE', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(timestamp);       
    }
    const formatTime= (timestamp ) => {
        return new Intl.DateTimeFormat('de-DE', {hour:'2-digit', minute:'2-digit' ,second: '2-digit'}).format(timestamp);  
    }
    const formatSecondToTime = (seconds)=> {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
    }
    const formatMeterinKM = (meter)=> {   
        const Strecke = meter /1000
        return Strecke.toFixed(2) + " km";
    }
    const formatArt = (cell, row) => {   
        if (cell === "erfolgreich") {
            return (
                <span>
                    <strong style={{ color: "green" }}>{cell}</strong>
                </span>
            );
        } else if (cell === "abholer") {
            return (
                <span>
                    <strong style={{ color: "SteelBlue" }}>{cell}</strong>
                </span>
            );
        }
        
        return (
            <strong style={{ color: "red" }}>{cell}</strong>
        );
    };
    
    

    useEffect(() => {
  
        get(child(ref(getDatabase()), `verguetung/` + FID))
          .then((snapshot) => {
            if (snapshot.exists()) {
              const data = snapshot.val();
              const unsort = Object.keys(data).map((k) => ({
                id: k,
                ...data[k],
              }));
              const resual = unsort.filter(
                (verguettung) => verguettung.isAktiv === true
              );
  
              setVerguettung(resual);
          
            } else {
              console.log("No data available");
            }
          })
          .catch((error) => {
            console.error(error);
          });
    
    }, [FID]);



    function buttonDeleteFormatter(cell, row) {
        return (
          <Button
            variant="outline-danger"
            row={ row }
            size="sm"
            onClick={() => handleDelete(row)}
          >{delsign} Löschen        
          </Button>
        );
    }
   
   
    const defaultSorted = [{
        dataField: 'userid',
        order: 'desc'
      }];

    const handleDelete = (row) => {         

            const username = SortUser.find(x=>x.id === row.userid)


            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    <h1>Bist du dir Sicher?</h1>
                    <p>Das du den Stopp von {username.name} löschen möchtest.</p>
                    <div className="d-flex justify-content-evenly">
                    <Button variant="outline-primary"  onClick={onClose}>Abbrechen</Button>
                    <Button
                      variant="outline-danger"
                      onClick={() => {
                        const removes = ref(db,'/dailybusiness/' + FID + '/' + row.datum +'/stopps/'+ row.id) 
                        remove(removes);


                        onClose();
                      }}
                    >
                      Ja, lösch es!
                    </Button>
                    </div>
                  </div>
                );
              }
            });
        
        
        
            
      };
    
    const handleDataChange = (Size ) => {       
        setRowCount(Size.dataSize)
    };
     
    const currencyNumberFormat = (value) => {
        return new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
          }).format(value)       
      }
    

    useEffect(() => {
        if (currentUser) {
          setFID(currentUser.photoURL);
        }
      }, [currentUser]);


    useEffect(() => {  //User Laden
    if(FID){
        get(child(dbRef, `users/` + FID))
        .then((snapshot) => {
        if (snapshot.exists()) {
            const data = snapshot.val();           
            const unsort = Object.keys(data)
            .map(k => ({                
                id: k,
                ...data[k]
            }));
            const resual = unsort.filter(user => user.isDelete === false);
            setSortUser(resual);  
            setLoadingUser(false)
            
        } else {
            console.log("No data available");
        }
        })
        .catch((error) => {
        console.error(error);
        });
    }

    }, [dbRef, FID]);

    useEffect(() => { //Touren Laden
        if(!loadingUser){
            get(child(dbRef, `touren/` + FID))
            .then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();           
                const unsort = Object.keys(data)
                .map(k => ({                
                    id: k,
                    ...data[k]
                }));
                const resual = unsort.filter(tour => tour.isAktiv === true);
                setSortTour(resual);  
                setLoadingTour(false)
                
            } else {
                console.log("No data available");
            }
            })
            .catch((error) => {
            console.error(error);
            });
        }
    
    }, [dbRef, FID,loadingUser]);

    useEffect(() => { //Fahrzeuge Laden
            if(!loadingTour){
                get(child(dbRef, `fahrzeuge/` + FID))
                .then((snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();           
                    const unsort = Object.keys(data)
                    .map(k => ({                
                        id: k,
                        ...data[k]
                    }));
                    const resual = unsort.filter(fahrzeug => fahrzeug.isAktiv === true);
                    setSortFahrzeug(unsort);  
                    setLoadingFahrzeug(false)
                    
                } else {
                    console.log("No data available");
                }
                })
                .catch((error) => {
                console.error(error);
                });
            }
        
    }, [dbRef, FID,loadingTour]);


    useEffect(() => { //Stopps Laden
       
        if(!loadingUser && !loadingTour && !loadingFahrzeug && loading){
           
            let newStopps =[];
          
            let vT = [];

            const optionArt = [{value:'erfolgreich',label: 'erfolgreich'},{value:'abholer',label: 'abholer'},{value:'vergeblich',label: 'vergeblich'}]
            const optionUser = []
            const optionTour = []
            const optionFahrzeug = []
            Object.values(SortUser).map((value) =>
                optionUser.push({value:value.id,label: value.name})
            );
            Object.values(SortTour).map((value) =>
                optionTour.push({value:value.id,label: value.bezeichnung})
            );
            Object.values(SortFahrzeug).map((value) =>
                optionFahrzeug.push({value:value.id,label: value.kennzeichen})
            );

            var tagecount = 0
            Object.entries(stoppauswahl).map(([key,value])=>{        
            const Datum = value.key;
          
                tagecount = tagecount +1

                Object.entries(value["stopps"]).map(([key1,value])=>{
                    const id = key1;
                  
                    const Verguettungsresult = verguettung.find((item) =>
                        item.tourassign.find((tour) => tour.value === value["tourid"])
                    );
                    

                    if (!vT.find(o => o.id === value["tourid"] )){
                        


                          vT.push({
                              //  datum: Datum,
                                id: value["tourid"],
                                tage: 0,
                                tournummer: optionTour.find(x => x.value === value["tourid"]).label ,                                
                                proS: Number(Verguettungsresult.prostopp),
                                count: 1,
                                maut : Number(Verguettungsresult.maut),
                                pauschale: Number(Verguettungsresult.pauschale),
                                diselzuschlag: Number(Verguettungsresult.diselzuschlag),
                                gproStopp: Number(Verguettungsresult.prostopp)
                            })
                    }else{
                       
                        const dvt =  vT.find(o => o.id === value["tourid"] )
                        dvt.count +=1
                        dvt.tage = tagecount
                        dvt.gproStopp += dvt.proS
                    }
                          

                   

                    newStopps.push({
                        datum: Datum,
                        id: id,
                        adress: value["adress"],
                        art: value["art"],
                        createdatetime:value["createdatetime"],
                        distance:value["distance"],
                        elapsedTime:value["elapsedTime"],
                        fzid:value["fzid"],
                        tourid:value["tourid"],
                        userid:value["userid"], // User["name"]
                    })               
                })       
                
               
                vT.sort()
                setGage(vT);
              //  console.log(vT)
               
               
           

                setRowCount(newStopps.length)
                setcolumns([
                    {
                        dataField: 'id',
                        text: 'ID',
                        hidden: true,
                        editable: false
                      },
                    {
                        dataField: 'datum',
                        text: 'Datum',
                        sort: true,
                        editable: false,
                        formatter: formatDate,
                        filter: dateFilter(),
                        align: 'center',
                  /*  headerEvents: {
                        onClick: (e, column, columnIndex) => alert('Click on Product ID header column')
                      }*/
                    },{
                        dataField: 'adress',
                        text: 'Adresse',
                        editable: false,
                        filter: textFilter()
                  }, {
                        dataField: 'art',
                        text: 'Art',
                        align: 'center',
                        formatter: formatArt,
                        filter: selectFilter({
                            options: optionArt
                        }),   
                        editor: {
                            type: Type.SELECT,
                            options: optionArt
                        }        
                  }, {
                        dataField: 'createdatetime',
                        text: 'Zeit',
                        sort: true,
                        editable: false,
                        formatter: formatTime,                        
                        align: 'center',
                  }, {
                        dataField: 'distance',
                        text: 'Fahrweg',
                        formatter:formatMeterinKM,
                        editable: false,
                        align: 'center',
                  }, {
                        dataField: 'elapsedTime',
                        text: 'Zeitaufwand',
                        formatter: formatSecondToTime,
                        editable: false,
                        align: 'center',
                  }, {
                        dataField: 'fzid',
                        text: 'Kennzeichen',
                        formatter: (cell, row) => {
                            return optionFahrzeug.find(x => x.value === cell).label;
                        },
                        sort: true,
                        align: 'center',
                        filter: selectFilter({
                            options: optionFahrzeug
                        }),   
                        editor: {
                            type: Type.SELECT,
                            options: optionFahrzeug
                        }        
                  }, {
                        dataField: 'tourid',
                        text: 'Tour',
                        align: 'center',
                        formatter: (cell, row) => {
                            return optionTour.find(x => x.value === cell).label;
                        },
                        sort: true,
                        filter: selectFilter({
                            options: optionTour
                        }),                         
                        editor: {
                            type: Type.SELECT,
                            options: optionTour
                        }        
                  }, {
                        dataField: 'userid',
                        text: 'Name',
                        formatter: (cell, row) => {
                            return optionUser.find(x => x.value === cell).label;
                        },
                        sort: true,      
                        filter: selectFilter({
                            options: optionUser
                        }), 
                        editor: {
                            type: Type.SELECT,
                            options: optionUser
                        }          
                  
                  },{            
                        dataField: "remove",            
                        text: "Löschen",
                        editable: false,
                        align: 'center',
                        formatter: (cellContent, row) => {
                            return (
                                <Button
                                variant="outline-danger"
                                row={ row }
                                size="sm"
                                onClick={() => handleDelete(row)}
                                >{delsign} Löschen        
                                </Button>
                            );
                        }
                  }]);

                setStopps(newStopps)
                setLoading(false)
                
               
            })
        }
    },[loadingUser, loadingTour, loadingFahrzeug, SortUser, SortTour, SortFahrzeug, stoppauswahl, verguettung,loading])


    useEffect(() => { 
        let gesamt = 0;
        Object.values(gage).map((item) => {
            //console.log("Item->",item);
            const gageOMOD = item['gproStopp'] + (item['tage'] * item['pauschale'])
            const gageMDiesel = gageOMOD + (gageOMOD /100 * item['diselzuschlag'])
            const gagegesamt = gageMDiesel + (item['tage'] * item['maut'])
            //gesamt += gagegesamt

            gesamt += gagegesamt//item['gproStopp'] + (item['tage'] * item['pauschale']) + (item['tage'] * item['maut'])
        })
          //  console.log((gesamt/100)*102.4)

        setGageGesamt(currencyNumberFormat(gesamt))
    },[gage])


    function calgage(item) {
        if (!item) return "0,00 €"; // 🔥 Falls `item` nicht existiert, Rückgabewert setzen
      
        const gproStopp = Number(item["gproStopp"]) || 0;
        const tage = Number(item["tage"]) || 0;
        const pauschale = Number(item["pauschale"]) || 0;
        const diselzuschlag = Number(item["diselzuschlag"]) || 0;
        const maut = Number(item["maut"]) || 0;
      
        const gageOMOD = gproStopp + tage * pauschale;
        const gageMDiesel = gageOMOD + (gageOMOD / 100) * diselzuschlag;
        const gagegesamt = gageMDiesel + tage * maut;
      
        return currencyNumberFormat(gagegesamt);
      }

    function beforeSaveCell(oldValue, newValue, row, column, done) {
        setTimeout(() => {
            const FildtoUpdate = column.dataField;

            const updates = {};
            updates['/dailybusiness/' + FID + '/' + row.datum +'/stopps/'+ row.id + '/'+ FildtoUpdate] = newValue;
            update(dbRef, updates).then(() => {
                done(true);
             // Data saved successfully!
           })
           .catch((error) => {
               done(false);
             // The write failed...
           });
       
        }, 0);
        return { async: true };
    }

   
    if (!loading) {
    return (
        <>
       
            <Card
            className={"mt-3 bg-light align-items-stretch myCardsx"}
            >
                <Card.Header className="text-center">
                    Angaben über die Stopps
                    <h5>Stopps Anzahl: {rowCount}</h5>
                    <h5>Gesamt Umsatz: {gagegesamt}</h5>

               
                    <div className="card-group">
                    {gage.sort((a, b) => a.tournummer > b.tournummer ? 1 : -1).map((item, index) => {
                    return ( 
                       
                       
                       
                    <Col>
                   
                        <Card  
                            style={{                            
                            borderColor: "lightblue",
                            borderRadius: "30px",                            
                            }}
                            className="mx-1"
                        >
                                <Card.Header  style={{
                                    fontWeight: "bold",
                                    backgroundColor: "lightblue",
                                    borderTopLeftRadius: "30px",
                                    borderTopRightRadius: "30px",
                                    }}>{item['tournummer']}</Card.Header>
                                <Card.Body>
                                    <Card.Title>Anzahl Stopps: {item['count']}</Card.Title>
                                    <Card.Text>
                                    <strong style={{   fontSize: 16 ,color: "blue"  }}>Umsatz: {calgage(item)}</strong>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        )
                    })}
                    
                    </div>
               
                  





                </Card.Header>
                <Card.Body className="d-flex flex-column">            
                    <div style={{ maxHeight: "620px", overflowY: "auto", overflowX: "auto" }}>                    
                    <BootstrapTable                                              
                        bootstrap4={ true }
                        defaultSorted={ defaultSorted } 
                        keyField='id' 
                        data={ Stopps } 
                        columns={ columns } 
                        striped    
                        hover
                        bordered={true}
                        wrapperClasses="table-responsive"
                        cellEdit={ cellEditFactory({
                             mode: 'click',
                             blurToSave: true,
                             beforeSaveCell                             
                             })}
                        filter={ filterFactory() }
                        noDataIndication="Keine Daten zum Anzeigen."
                        pagination={ paginationFactory({
                            showTotal: true,
                            hidePageListOnlyOnePage: true
                            
                        })}
                        
                        onDataSizeChange={(dataSize ) => {
                            handleDataChange(dataSize )
                        }}     
                      //  selectRow={ selectRow }                  
                        />       
                        </div>              
                </Card.Body>
            </Card>
        </>
        )
    }

}