import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Col,
  Row,
  Button,
  Tabs,
  Tab,
  OverlayTrigger,
  Popover,
  Stack,
} from "react-bootstrap";
import useWindowDimensions from "../../useMediaQuery";
import { useAuth } from "../../../context/authContext";
import DatePicker, { registerLocale } from "react-datepicker";
import Select from "react-select";
import Switch from "react-switch";
import de from "date-fns/locale/de";
import { ref, getDatabase, onValue } from "firebase/database";
registerLocale("de", de);

export const TourForm = ({
  childToParent,
  setShowNewTour,
  formdata,
  IsnewTour,
}) => {
  const [validated, setValidated] = useState(true);
  const { width } = useWindowDimensions();
  const { currentUser } = useAuth();
  const FID = currentUser.photoURL;
  const [Tourid, setTourid] = useState("");
  const [isAktiv, setisAktiv] = useState(true);
  const db = getDatabase();
  const [userOption, setuserOption] = useState();

  useEffect(() => {
    const optionsUsers = [];
    const dbRef = ref(db, `users/` + FID);
    return onValue(dbRef, (snap) => {
      if (snap.exists()) {
        const data = snap.val();
        const unsort = Object.keys(data).map((k) => ({
          id: k,
          ...data[k],
        }));
        const resual = unsort.filter(
          (user) => user.isDelete === false || user.isAktiv === true
        );

        resual.forEach(function (user) {
          optionsUsers.push({ value: user.id, label: user.name });
        });
        setuserOption(optionsUsers);
        return;
      }
      //setLoading(true)
    });
  }, [db, FID]);

  const styles = {
    myCoolButton: {
      paddingRight: "1vw",
    },
  };
  const [TourData, setFormData] = useState({
    aktiveDate: new Date().getTime(),
    createDate: new Date().getTime(),
    fid: FID,
    isAktiv: true,
    bezeichnung: "",
    userassign: [],
  });

  /*const popover = (
    <Popover id="popover-basic">
      <Popover.Header className="h3 text-center">
        Verknüfung mit einen Fahrer
      </Popover.Header>
      <Popover.Body>
        Hier die Fahrer oder den Fahrer auswählen der diese Tour Fährt. Dies
        soll falsch eingaben der Fahrer vermeinden.
        <br />
        <br />
        Die Fahrer werden, wenn Sie mehrer Touren zur auswahl haben, beim Start
        der App nach Ihrer heuten Tour gefragt.
        <br />
        <br />
        Wenn der Fahrer nur eine Tour hat, wird diese Abfrage übersprungen, so
        das der Fahrer die Ihn vorgegebene Tour Fährt.
        <br />
        <br />
        Welche Tour er Fährt wird Ihn auch in der App angezeigt.
      </Popover.Body>
    </Popover>
  );*/

  const handleChangeisaktiv = (AktivChecked) => {
    setisAktiv(AktivChecked);
    setFormData({ ...TourData, isAktiv: AktivChecked });
  };

  useEffect(() => {
    if (!IsnewTour) {
      setTourid(formdata.key);
      setFormData(formdata.tour);
    }
  }, [IsnewTour, formdata]);

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => childToParent(e, TourData, Tourid)}
        className="mt-3"
      >
        <Card
          className={
            width < 768
              ? "bg-light align-items-stretch myCard"
              : "align-items-stretch myCard"
          }
        >
          <Card.Header className="text-center">
            Angaben über die Tour
          </Card.Header>

          <Card.Body className="d-flex flex-column">
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="home" title="Allgemeine Angaben">
                <Row className="mb-3">
                  <Form.Group as={Col} md="12">
                    <Stack direction="horizontal" gap={3}>
                      <Form.Label>Ist Aktiv</Form.Label>
                      <label>
                        <Switch
                          onChange={handleChangeisaktiv}
                          checked={TourData.isAktiv}
                          width={80}
                          height={30}
                          handleDiameter={26}
                          uncheckedIcon={
                            <div className="switch-text">Nein</div>
                          }
                          checkedIcon={<div className="switch-text">Ja</div>}
                        />
                      </label>
                    </Stack>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md="6"
                    style={styles.myCoolButton}
                    controlId="validationKennzeichen"
                  >
                    <Form.Label>Bezeichnung der Tour</Form.Label>

                    <Form.Control
                      required
                      type="text"
                      placeholder="Tour Bezeichnung"
                      value={TourData.bezeichnung || ""}
                      onChange={(event) =>
                        setFormData({
                          ...TourData,
                          bezeichnung: event.target.value,
                        })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      Bitte eine Bezeichnung eingeben!{" "}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>
                      Sieht gut aus!
                    </Form.Control.Feedback>
                  </Form.Group>

               
                    <Form.Group as={Col} md="6" controlId="validationAntrieb">
                      <Form.Label>Wer fährt die Tour</Form.Label>
                      <Select
                        isMulti
                        options={userOption}
                        value={TourData.userassign || ""}
                        onChange={(e) => {
                          setFormData({ ...TourData, userassign: e });
                        }}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </Form.Group>
                  
                </Row>
              </Tab>
            </Tabs>
          </Card.Body>
          <Card.Footer>
            <div className="row">
              <div className="col d-grid gap-2 mt-auto">
                <Button
                  variant="outline-danger "
                  onClick={() => {
                    setShowNewTour(false);
                  }}
                >
                  Abbrechen
                </Button>
              </div>
              <div className="col d-grid gap-2 mt-auto">
                <Button
                  className="float-right"
                  variant="outline-success"
                  type="submit"
                >
                  {IsnewTour ? "Speichern" : "Update"}
                </Button>
              </div>
            </div>
          </Card.Footer>
        </Card>
      </Form>
    </>
  );
};
