import React, { useState, useEffect } from "react";
import "../../../App.css";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Tab,
  Row,
  Col,
  Button,
  ListGroup,
  Badge,
  Stack,
  Container,
} from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import Switch from "react-switch";
import {
  ref,
  getDatabase,
  onValue,
  update
} from "firebase/database";
import { useAuth } from "../../../context/authContext";
import { FZForm } from "../Fahrzeuge/FZForm";
import useWindowDimensions from "../../useMediaQuery";
import "../../../styles/switches.css";
import toast from "react-hot-toast";

export const Fahrzeuge = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const isMobile = width < 768;
  const [showNewFZ, setShowNewFZ] = useState(false);  
  const { currentUser, isAdmin } = useAuth();
  const [IsnewFZ, setIsnewFZ] = useState(true);
  const [FZData, SetFZData] = useState({ FZ: null, key: null });
  const db = getDatabase();
  const [FID, setFID] = useState("");
  const [fzart, setfzart] = useState(true);
  const [showAktiveFZ, setShowAktiveFZ] = useState(true);
  const [SortFZ, setSortFZ] = useState([]);
  

  useEffect(() => {
    if (!isAdmin) {
      navigate(-1);
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    if (currentUser) {
      setFID(currentUser.photoURL);
    }
  }, [currentUser]);

  useEffect(() => {
    const dbRef = ref(db, `fahrzeuge/` + FID);
    return onValue(dbRef, (snap) => {
      if (snap.exists()) {
        const data = snap.val();
        const unsort = Object.keys(data).map((k) => ({
          id: k,
          ...data[k],
        }));
        const filtered = unsort.filter(
          (fz) => fz.islkw === fzart && (showAktiveFZ ? fz.isAktiv : !fz.isAktiv)
        );
        setSortFZ(filtered);
      } else {
        setSortFZ([]);
      }
    });
  }, [fzart, showAktiveFZ, db, FID]);

  const handleChange = (nextChecked) => {
    setfzart(nextChecked);
  };

  const handleAktiveFZChange = (checked) => {
    setShowAktiveFZ(checked);
  };

  const handleClickFZ = (data, key) => {
    SetFZData({ FZ: data, key: key });
    setIsnewFZ(false);
    setShowNewFZ(true);
  };


  const handleSaveFZ = (e, FZdata, expiryData, FZid) => {
    e.preventDefault();
    console.log("Speichern in Firebase:", FZdata, expiryData, FZid);
  
    const updates = {};
    updates[`/fahrzeuge/${FID}/${FZid}`] = FZdata;
    updates[`/mhd/${FID}/${FZid}`] = expiryData;
  
    update(ref(db), updates)
      .then(() => {
        toast.success("Kennzeichen " + FZdata.kennzeichen + " wurde Aktualisiert");
        console.log("Speicherung erfolgreich!");
        setShowNewFZ(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(
          "Es ist ein fehler beim Update aufgeteten\n" +
            errorCode +
            "\n" +
            errorMessage
        );
        console.error("Fehler beim Speichern:", error);
      });
  };


  return (
    <>
      {currentUser ? (
        <Container fluid>
          <Row>
            <Col xs={12} sm={12} lg={3} md={4}>
              {!isMobile || !showNewFZ ? (
                <Card className="mt-3 bg-light align-items-stretch myCard">
                  <Card.Header className="d-flex justify-content-between">
                    <div>Fahrzeuge</div>
                    <div className="d-flex gap-3">
                      <label>

                        <Switch
                          className="custom-switch"     
                          onChange={handleChange}
                          width={80}
                          height={30}
                          handleDiameter={26}
                          checked={fzart}                                                   
                          uncheckedIcon={<div className="switch-text">PKW</div>}
                          checkedIcon={<div className="switch-text">LKW</div>}
                        />
                      </label>
                      <label>
                        <Switch
                          onChange={handleAktiveFZChange}
                          checked={showAktiveFZ}
                          width={80}
                          height={30}
                          handleDiameter={26}
                          className="custom-switch"
                          uncheckedIcon={<div className="switch-text">Inaktiv</div>}
                          checkedIcon={<div className="switch-text">Aktiv</div>}
                        />
                      </label>
                    </div>
                  </Card.Header>
                  <Card.Body className="d-flex flex-column">
                    <Tab.Container id="Fahrzeuge-Tab">
                      <Row>
                        <Col sm={12}>
                          <ListGroup>
                            <Scrollbars autoHeight autoHeightMax="calc(100vh - 230px)">
                              {SortFZ.map((fz) => (
                                <ListGroup.Item
                                  key={fz.id}
                                  id={fz.id}
                                  action
                                  onClick={() => handleClickFZ(fz, fz.id)}
                                  className="d-flex justify-content-between align-items-center"
                                >
                                  {`${fz.kennzeichen}`}
                                  <Stack direction="horizontal" gap={3}>
                                    {fz.isAktiv ? (
                                      <Badge bg="success">Aktiv</Badge>
                                    ) : (
                                      <Badge bg="danger">Deaktiv</Badge>
                                    )}
                                    {fz.isleihfahrzeug && (
                                      <Badge bg="info">Leihfahrzeug</Badge>
                                    )}
                                  </Stack>
                                </ListGroup.Item>
                              ))}
                            </Scrollbars>
                          </ListGroup>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </Card.Body>
                  <Card.Footer style={{
                    position: "sticky",
                    bottom: 0,
                    backgroundColor: "#f8f9fa", // Hintergrundfarbe für visuelle Abgrenzung
                    zIndex: 10,
                  }}>
                   <div className="d-grid gap-2">
                    <Button variant="outline-success" onClick={() => setShowNewFZ(true)}>
                      Neues Fahrzeug hinzufügen
                    </Button>
                   </div>
                    
                  </Card.Footer>
                </Card>
              ) : null}
            </Col>
            {showNewFZ && (
              <Col xs={12} sm={12} lg={9} md={8}>
                <FZForm setShowNewFZ={setShowNewFZ} formdata={FZData} IsnewFZ={IsnewFZ} childToParent={handleSaveFZ} />
              </Col>
            )}
          </Row>
        </Container>
      ) : (
        navigate("/login")
      )}
    </>
  );
};
