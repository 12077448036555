import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Col,
  Row,
  Button,
  Tabs,
  Tab,
  OverlayTrigger,
  Popover,
  Stack,
  InputGroup,
} from "react-bootstrap";
import {
  ref,
  getDatabase,
  onValue,
} from "firebase/database";
import useWindowDimensions from "../../useMediaQuery";
import { useAuth } from "../../../context/authContext";
import Select from "react-select";
import Switch from "react-switch";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../../../styles/switches.css";

export const VerguetungForm = ({
  childToParent,
  setShowNewVerguetung,
  formdata,
  IsnewVerguetung,
}) => {
  const eurosign = <i className="bi bi-currency-euro"></i>;
  const percentsign = <i className="bi bi-percent"></i>;

  const [validated, setValidated] = useState(true);
  const { width } = useWindowDimensions();
  const { currentUser } = useAuth();
  const FID = currentUser.photoURL;
  const [VerguetungID, setVerguetungID] = useState(formdata.key);
  const [isAktiv, setisAktiv] = useState(true);
  const db = getDatabase();

  // ⬇️ Hier wird das Dropdown gefüllt (touren):
  const [userOption, setuserOption] = useState([]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3" className="text-center">
        Verknüfung mit einem Fahrer
      </Popover.Header>
      <Popover.Body>
        Hier die Fahrer oder den Fahrer auswählen, der diese Tour fährt.
        Dies soll falsche Eingaben der Fahrer vermeiden. <br />
        <br />
        Die Fahrer werden, wenn sie mehrere Touren zur Auswahl haben, beim Start
        der App nach ihrer heutigen Tour gefragt. <br />
        <br />
        Wenn der Fahrer nur eine Tour hat, wird diese Abfrage übersprungen, so
        dass der Fahrer die ihm vorgegebene Tour fährt. <br />
        <br />
        Welche Tour er fährt, wird ihm auch in der App angezeigt.
      </Popover.Body>
    </Popover>
  );

  // ⬇️ VerguetungData - Initialwerte
  const [VerguetungData, setFormData] = useState({
    aktiveDate: new Date().getTime(),
    createDate: new Date().getTime(),
    fid: FID,
    isAktiv: true,
    pauschale: "",
    prostopp: "",
    maut: "",
    diselzuschlag: "",
    tourassign: [],
  });

  // ⬇️ useEffect zum Laden der Touren -> userOption:
  useEffect(() => {
    const optionsUsers = [];
    const dbRef = ref(db, `touren/` + FID);
    return onValue(dbRef, (snap) => {
      if (snap.exists()) {
        const data = snap.val();
        const unsort = Object.keys(data).map((k) => ({
          id: k,
          ...data[k],
        }));
        const resual = unsort.filter((touren) => touren.isAktiv === true);

        resual.forEach((touren) => {
          optionsUsers.push({ value: touren.id, label: touren.bezeichnung });
        });
        setuserOption(optionsUsers);
      }
    });
  }, [db, FID]);

  // ⬇️ Beim Editieren vorhandener Verguetungen Daten übernehmen:
  useEffect(() => {
    if (!IsnewVerguetung) {
      setVerguetungID(formdata.key);
      setFormData(formdata.verguetung);
    }
  }, [IsnewVerguetung, formdata]);

  // ⬇️ Switch "Ist Aktiv" ändern
  const handleChangeisaktiv = (AktivChecked) => {
    setisAktiv(AktivChecked);
    setFormData({ ...VerguetungData, isAktiv: AktivChecked });
  };

  // ⬇️ Styles (optional)
  const styles = {
    myCoolButton: {
      paddingRight: "1vw",
    },
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => childToParent(e, VerguetungData, VerguetungID)}
        className="mt-3"
      >
        <Card
          className={
            width < 768
              ? "bg-light align-items-stretch myCard"
              : "align-items-stretch myCard"
          }
        >
          <Card.Header className="text-center">
            Angaben über die Vergütung
          </Card.Header>

          <Card.Body className="d-flex flex-column">
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="home" title="Allgemeine Angaben">
                <Row className="mb-3">
                  <Form.Group as={Col} md="12">
                    <Stack direction="horizontal" gap={3}>
                      <Form.Label>Ist Aktiv</Form.Label>
                      <label>
                        <Switch
                          className="custom-switch"
                          onChange={handleChangeisaktiv}
                          width={80}
                          height={30}
                          handleDiameter={26}
                          checked={VerguetungData.isAktiv}
                          uncheckedIcon={<div className="switch-text">Nein</div>}
                          checkedIcon={<div className="switch-text">Ja</div>}
                        />
                      </label>
                    </Stack>
                  </Form.Group>
                </Row>

                {/* Pauschale + pro Stopp */}
                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md="6"
                    style={styles.myCoolButton}
                    controlId="validationPauschale"
                  >
                    <Form.Label>Pauschale der Vergütung</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Text id="inputGroupPrepend">
                        {eurosign}
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="number"
                        min="0.00"
                        step="0.01"
                        placeholder="Pauschale"
                        value={VerguetungData.pauschale || ""}
                        onChange={(event) =>
                          setFormData({
                            ...VerguetungData,
                            pauschale: event.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Bitte eine Pauschale eingeben!
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>Sieht gut aus!</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="6"
                    style={styles.myCoolButton}
                    controlId="validationprostopp"
                  >
                    <Form.Label>Vergütung pro Stopp</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Text id="inputGroupPrepend">
                        {eurosign}
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="number"
                        min="0.00"
                        step="0.01"
                        placeholder="pro Stopp"
                        value={VerguetungData.prostopp || ""}
                        onChange={(event) =>
                          setFormData({
                            ...VerguetungData,
                            prostopp: event.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Bitte einen Betrag eingeben!
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>Sieht gut aus!</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>

                {/* Maut + Dieselzuschlag */}
                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md="6"
                    style={styles.myCoolButton}
                    controlId="validationMaut"
                  >
                    <Form.Label>Pauschale für Maut</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Text id="inputGroupPrepend">
                        {eurosign}
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="number"
                        min="0.00"
                        step="0.01"
                        placeholder="Maut"
                        value={VerguetungData.maut || ""}
                        onChange={(event) =>
                          setFormData({
                            ...VerguetungData,
                            maut: event.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Bitte eine Pauschale für die Maut eingeben!
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>Sieht gut aus!</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="6"
                    style={styles.myCoolButton}
                    controlId="validationdiesel"
                  >
                    <Form.Label>Dieselzuschlag</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Text id="inputGroupPrepend">
                        {percentsign}
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="number"
                        min="0.0000"
                        step="0.0001"
                        placeholder="Prozent"
                        value={VerguetungData.diselzuschlag || ""}
                        onChange={(event) =>
                          setFormData({
                            ...VerguetungData,
                            diselzuschlag: event.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Bitte einen prozentualen Wert eingeben!
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>Sieht gut aus!</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>

                {/* Multi-Select (Touren zuweisen) */}
                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationTour"
                    style={styles.myCoolButton}
                  >
                    <Form.Label>Welche Tour soll die Vergütung bekommen?</Form.Label>

                    {/* 
                      WICHTIG: Hier die Props zum "Portalen" des Menüs.
                      So wird das Menü nicht vom umgebenden Container abgeschnitten.
                    */}
                    <Select
                      isMulti
                      options={userOption}
                      value={VerguetungData.tourassign || ""}
                      onChange={(e) => {
                        setFormData({ ...VerguetungData, tourassign: e });
                      }}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </Form.Group>
                </Row>
              </Tab>
            </Tabs>
          </Card.Body>
          <Card.Footer>
            <div className="row">
              <div className="col d-grid gap-2 mt-auto">
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    setShowNewVerguetung(false);
                  }}
                >
                  Abbrechen
                </Button>
              </div>
              <div className="col d-grid gap-2 mt-auto">
                <Button className="float-right" variant="outline-success" type="submit">
                  {IsnewVerguetung ? "Speichern" : "Update"}
                </Button>
              </div>
            </div>
          </Card.Footer>
        </Card>
      </Form>
    </>
  );
};
