import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  FloatingLabel,
  Button,
  Accordion,
  Table,
} from "react-bootstrap";
import { getDatabase, ref, set, get } from "firebase/database";
import toast from "react-hot-toast";

export default function UrlaubsdatenVerwalten({ FID, CurrentMitarbeiter }) {
  const db = getDatabase();
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedUser, setSelectedUser] = useState("");

  const [urlaubsdaten, setUrlaubsdaten] = useState({
    anspruch: 26,
    beantragteTage: 0,
    genommeneTage: 0,
    krankheitstage: 0,
    rest: 0,
    sonderurlaub: 0,
  });

  // ➜ Hier speichern wir ALLE Urlaube aus der DB, um sie im Accordion darzustellen
  const [allVacationData, setAllVacationData] = useState({});

  // 1) Urlaubsdaten für das Formular laden, wenn Jahr + User gewählt
  useEffect(() => {
    if (!FID || !selectedYear || !selectedUser) return;

    const urlaubRef = ref(db, `urlaube/${FID}/${selectedYear}/${selectedUser}`);
    get(urlaubRef).then((snapshot) => {
      if (snapshot.exists()) {
        setUrlaubsdaten(snapshot.val());
      } else {
        // Falls keine Daten vorhanden sind: Standardwerte setzen
        setUrlaubsdaten({
          anspruch: 26,
          beantragteTage: 0,
          genommeneTage: 0,
          krankheitstage: 0,
          rest: 0,
          sonderurlaub: 0,
        });
      }
    });
  }, [FID, selectedYear, selectedUser, db]);

  // 2) ALLE Datensätze aus 'urlaube/FID' laden, um das Accordion darunter zu bauen
  useEffect(() => {
    if (!FID) return;
    const dbRef = ref(db, `urlaube/${FID}`);
    get(dbRef).then((snapshot) => {
      if (snapshot.exists()) {
        setAllVacationData(snapshot.val());
      } else {
        setAllVacationData({});
      }
    });
  }, [FID, db]);

  // 3) Urlaubsdaten speichern
  const handleSave = async () => {
    if (!selectedUser) {
      toast.error("Bitte einen Mitarbeiter auswählen!");
      return;
    }
    const urlaubRef = ref(db, `urlaube/${FID}/${selectedYear}/${selectedUser}`);
    await set(urlaubRef, urlaubsdaten);
    toast.success("Urlaubsdaten erfolgreich gespeichert!");

    // Option: danach erneut allVacationData laden, damit Accordion aktuell bleibt:
    get(ref(db, `urlaube/${FID}`)).then((snapshot) => {
      if (snapshot.exists()) {
        setAllVacationData(snapshot.val());
      }
    });
  };

  // ⬇️ Accordion: Daten pro Mitarbeiter (userId) gruppieren.
  //    Strukturelle Idee: { userId: { 2023: {...}, 2024: {...}, ...}, ... }
  //    Da in Firebase: urlaube/FID/Jahr/userId => wir müssen umformen.

  // Hilfs-Objekt: "userMap" => { userId: { 2023: {...}, 2024: {...} } }
  const userMap = {};
  // allVacationData hat die Struktur: { 2023: { userA: {...}, userB: {...} }, 2024: {...} }
  for (const yearKey in allVacationData) {
    const usersInYear = allVacationData[yearKey];
    // usersInYear = { userA: {...}, userB: {...} }
    for (const userId in usersInYear) {
      if (!userMap[userId]) {
        userMap[userId] = {};
      }
      userMap[userId][yearKey] = usersInYear[userId];
    }
  }
  // => userMap z.B.:
  // { userA: { 2023: {...}, 2024: {...} }, userB: { 2023: {...} }, ... }

  // Wir brauchen den "Namen" des Mitarbeiters aus CurrentMitarbeiter
  // Falls es den user im CurrentMitarbeiter nicht gibt, schreiben wir "Unbekannt".
  const getUserName = (userId) => {
    const userObj = CurrentMitarbeiter[userId];
    return userObj ? userObj.name : "Unbekannt";
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col md={8}>
          {/* ------------------------------------------------------
              1) Das bisherige Formular zum Auswählen/Bearbeiten
          ------------------------------------------------------ */}
          <Card className="shadow-sm mb-4">
            <Card.Header className="bg-primary text-white text-center">
              <h4 className="my-1">Urlaubsdaten verwalten</h4>
            </Card.Header>
            <Card.Body>
              <Row className="g-3">
                <Col md={6}>
                  {/* Jahr auswählen: Floating Label */}
                  <FloatingLabel controlId="yearSelect" label="Jahr auswählen">
                    <Form.Select
                      aria-label="Jahr auswählen"
                      value={selectedYear}
                      onChange={(e) => setSelectedYear(Number(e.target.value))}
                    >
                      {[...Array(10)].map((_, i) => {
                        const year = currentYear - 5 + i;
                        return (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </FloatingLabel>
                </Col>

                <Col md={6}>
                  {/* Mitarbeiter auswählen */}
                  <FloatingLabel controlId="userSelect" label="Mitarbeiter auswählen">
                    <Form.Select
                      aria-label="Mitarbeiter auswählen"
                      value={selectedUser}
                      onChange={(e) => setSelectedUser(e.target.value)}
                    >
                      <option value="">Bitte auswählen</option>
                      {Object.keys(CurrentMitarbeiter).map((id) => (
                        <option key={id} value={id}>
                          {CurrentMitarbeiter[id].name}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
              </Row>

              {/* Nur anzeigen, wenn Mitarbeiter gewählt */}
              {selectedUser && (
                <Form className="mt-4">
                  <Row className="g-3">
                    <Col md={4}>
                      <FloatingLabel controlId="anspruchInput" label="Urlaubsanspruch">
                        <Form.Control
                          type="number"
                          value={urlaubsdaten.anspruch}
                          onChange={(e) =>
                            setUrlaubsdaten({
                              ...urlaubsdaten,
                              anspruch: Number(e.target.value),
                            })
                          }
                        />
                      </FloatingLabel>
                    </Col>

                    <Col md={4}>
                      <FloatingLabel controlId="beantragteTageInput" label="Beantragte Tage">
                        <Form.Control
                          type="number"
                          value={urlaubsdaten.beantragteTage}
                          onChange={(e) =>
                            setUrlaubsdaten({
                              ...urlaubsdaten,
                              beantragteTage: Number(e.target.value),
                            })
                          }
                        />
                      </FloatingLabel>
                    </Col>

                    <Col md={4}>
                      <FloatingLabel controlId="genommeneTageInput" label="Genommene Tage">
                        <Form.Control
                          type="number"
                          value={urlaubsdaten.genommeneTage}
                          onChange={(e) =>
                            setUrlaubsdaten({
                              ...urlaubsdaten,
                              genommeneTage: Number(e.target.value),
                            })
                          }
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>

                  <Row className="g-3 mt-3">
                    <Col md={4}>
                      <FloatingLabel controlId="krankheitstageInput" label="Krankheitstage">
                        <Form.Control
                          type="number"
                          value={urlaubsdaten.krankheitstage}
                          onChange={(e) =>
                            setUrlaubsdaten({
                              ...urlaubsdaten,
                              krankheitstage: Number(e.target.value),
                            })
                          }
                        />
                      </FloatingLabel>
                    </Col>

                    <Col md={4}>
                      <FloatingLabel controlId="restInput" label="Resturlaub">
                        <Form.Control
                          type="number"
                          value={urlaubsdaten.rest}
                          onChange={(e) =>
                            setUrlaubsdaten({
                              ...urlaubsdaten,
                              rest: Number(e.target.value),
                            })
                          }
                        />
                      </FloatingLabel>
                    </Col>

                    <Col md={4}>
                      <FloatingLabel controlId="sonderurlaubInput" label="Sonderurlaub">
                        <Form.Control
                          type="number"
                          value={urlaubsdaten.sonderurlaub}
                          onChange={(e) =>
                            setUrlaubsdaten({
                              ...urlaubsdaten,
                              sonderurlaub: Number(e.target.value),
                            })
                          }
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>

                  <div className="d-grid gap-2 mt-4">
                    <Button
                      variant="success"
                      size="lg"
                      onClick={handleSave}
                    >
                      Speichern
                    </Button>
                  </div>
                </Form>
              )}
            </Card.Body>
          </Card>

          {/* ------------------------------------------------------
              2) Accordion-Übersicht aller gespeicherten Einträge
          ------------------------------------------------------ */}
          {Object.keys(userMap).length > 0 && (
            <Card className="shadow-sm">
              <Card.Header className="bg-secondary text-white">
                <h5 className="my-1">Gespeicherte Urlaubsdaten</h5>
              </Card.Header>
              <Card.Body>
                <Accordion defaultActiveKey="0" alwaysOpen>
                  {Object.keys(userMap).map((userId, index) => {
                    const userName = getUserName(userId);
                    const yearsObj = userMap[userId]; // z.B. { 2023: {...}, 2024: {...} }

                    return (
                      <Accordion.Item eventKey={String(index)} key={userId}>
                        <Accordion.Header>
                          {userName} 
                        </Accordion.Header>
                        <Accordion.Body>
                          {/* Tabelle mit allen Jahren */}
                          <Table bordered hover responsive>
                            <thead>
                              <tr>
                                <th>Jahr</th>
                                <th>Anspruch</th>
                                <th>Beantragt</th>
                                <th>Genommen</th>
                                <th>Krankheitstage</th>
                                <th>Resturlaub</th>
                                <th>Sonderurlaub</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(yearsObj)
                                .sort() // sortiere nach Jahr
                                .map((year) => {
                                  const data = yearsObj[year];
                                  return (
                                    <tr key={year}>
                                      <td>{year}</td>
                                      <td>{data.anspruch ?? 0}</td>
                                      <td>{data.beantragteTage ?? 0}</td>
                                      <td>{data.genommeneTage ?? 0}</td>
                                      <td>{data.krankheitstage ?? 0}</td>
                                      <td>{data.rest ?? 0}</td>
                                      <td>{data.sonderurlaub ?? 0}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
}
