import React, { useState, useEffect } from "react";
import { Button, Form, Modal, Alert } from "react-bootstrap";
import { getDatabase, ref, set, get, onValue ,push} from "firebase/database";
import moment from "moment";
import "moment/locale/de";
import Feiertage from "moment-feiertage";
import toast from "react-hot-toast";

export function UrlaubBeantragen({ FID, year, UID, isAdmin, CurrentUser }) {
  const db = getDatabase();
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [anlass, setAnlass] = useState("Urlaub");
  const [kommentar, setKommentar] = useState("");
  const [error, setError] = useState("");
  const [existingUrlaub, setExistingUrlaub] = useState([]);
  const [remainingDays, setRemainingDays] = useState(0);

  const today = moment().format("YYYY-MM-DD");

  useEffect(() => {
    if (!FID || !year || !UID) return;
  
    const urlaubRef = ref(db, `kalender/${FID}/${year}/${UID}`);
    const urlaubsKontoRef = ref(db, `urlaube/${FID}/${year}/${UID}`);
  
    // 🚀 Echtzeit-Listener für bestehende Urlaube
    const unsubscribeUrlaube = onValue(urlaubRef, (snapshot) => {
      if (snapshot.exists()) {
        setExistingUrlaub(Object.values(snapshot.val()));
      } else {
        setExistingUrlaub([]);
      }
    });
  
    // 🚀 Echtzeit-Listener für das Urlaubskonto
    const unsubscribeUrlaubsKonto = onValue(urlaubsKontoRef, (snapshot) => {
      if (snapshot.exists()) {
        const urlaubsData = snapshot.val();
        setRemainingDays(
          urlaubsData.anspruch +
            urlaubsData.sonderurlaub +
            urlaubsData.rest -
            (urlaubsData.genommeneTage + urlaubsData.beantragteTage)
        );
      }
    });
  
    // 🚀 Cleanup der Echtzeit-Listener beim Verlassen der Komponente
    return () => {
      unsubscribeUrlaube();
      unsubscribeUrlaubsKonto();
    };
  }, [FID, year, UID, db]);
  

  function formatDate(date) {
    return moment(date).format("DD.MM.YYYY");
  }

  function calculateWorkdays(startDate, endDate) {
    let sDate = new Date(startDate);
    const eDate = new Date(endDate);
    sDate.setHours(0, 0, 0);
    eDate.setHours(0, 0, 0);
    let mydays = 0;

    while (sDate <= eDate) {
      if (!Feiertage(sDate).isHoliday("HH") && sDate.getDay() > 0 && sDate.getDay() < 6) {
        mydays++;
      }
      sDate.setDate(sDate.getDate() + 1);
    }
    return mydays;
  }

  const checkForConflicts = (newStartDate, newEndDate) => {
    return existingUrlaub.some(({ startDate, endDate }) => {
      const start = moment(startDate, "DD.MM.YYYY");
      const end = moment(endDate, "DD.MM.YYYY");
      return (
        (start.isBetween(newStartDate, newEndDate, null, "[]")) ||
        (end.isBetween(newStartDate, newEndDate, null, "[]")) ||
        (newStartDate.isBetween(start, end, null, "[]")) ||
        (newEndDate.isBetween(start, end, null, "[]"))
      );
    });
  };

  const handleSubmit = async (e) => {
  e.preventDefault();
  setError("");

  if (moment(endDate).isBefore(moment(startDate))) {
    setError("Das Enddatum darf nicht vor dem Startdatum liegen.");
    return;
  }

  const workdays = calculateWorkdays(startDate, endDate);
  if (workdays === 0) {
    setError("Ihr Urlaub enthält nur Wochenenden oder Feiertage. Bitte andere Tage wählen.");
    return;
  }

  const newStartDate = moment(startDate, "YYYY-MM-DD");
  const newEndDate = moment(endDate, "YYYY-MM-DD");

  // 🚨 Check für Überschneidungen
  if (checkForConflicts(newStartDate, newEndDate)) {
    toast.error("Der gewählte Zeitraum überschneidet sich mit einem bestehenden Urlaub.");
    return;
  }

  // 🚨 Check für verbleibende Urlaubstage
  if (workdays > remainingDays) {
    toast.error(
      `Sie haben nur noch ${remainingDays} Urlaubstage übrig, aber beantragen ${workdays} Tage.`
    );
    return;
  }

  const newUrlaub = {
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
    status: "wartend",
    anlass,
    kommentar,
    anzahltage: workdays,
    beantragtAm: formatDate(moment().format("YYYY-MM-DD")),
    erstelltVon: CurrentUser.name,
  };

  const urlaubID = `urlaub_${startDate.replace(/-/g, "")}`;
  const urlaubRef = ref(db, `kalender/${FID}/${year}/${UID}/${urlaubID}`);

  await set(urlaubRef, newUrlaub);

  // 🚀 Aktualisiere die Urlaubsübersicht unter `/urlaube/{FID}/{year}/{UID}/`
  const urlaubsÜbersichtRef = ref(db, `urlaube/${FID}/${year}/${UID}`);
  const snapshot = await get(urlaubsÜbersichtRef);

  if (snapshot.exists()) {
    const userData = snapshot.val();
    const neueBeantragteTage = (userData.beantragteTage || 0) + workdays;

    await set(urlaubsÜbersichtRef, {
      ...userData,
      beantragteTage: neueBeantragteTage,
    });
  }

  // 🚀 Nachricht an alle Admins senden
  //const adminsRef = ref(db, `admins/${FID}`);
 // const adminsSnapshot = await get(adminsRef);

  //if (adminsSnapshot.exists()) {
   // const admins = Object.keys(adminsSnapshot.val());

  //  for (const adminUID of admins) {
      const noticeRef = ref(db, `notice/${FID}/${FID}`);
      await set(push(noticeRef), {
        message: `📢 ${CurrentUser.name} hat einen Urlaubsantrag gestellt: ${formatDate(startDate)} - ${formatDate(endDate)} (${workdays} Tage)`,
        timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
        new: true,
        type: "info",
        ref: urlaubID,
      });
   // }
  //}

  setShow(false);
  toast.success(`Urlaubsantrag erfolgreich eingereicht! (${workdays} Tage)`);
};


  return (
    <>
      <Button variant="primary" onClick={() => setShow(true)}>
        Urlaub beantragen
      </Button>

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Urlaubsantrag stellen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}

          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Startdatum</Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                min={isAdmin ? "" : today}
                max={isAdmin ? "" : `${year}-12-31`} // 🆕 Maximal erlaubt bis 31.12. des aktuellen Jahres
                onChange={(e) => setStartDate(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Enddatum</Form.Label>
              <Form.Control
                type="date"
                value={endDate}
                min={isAdmin ? "" : startDate || today}
                max={isAdmin ? "" : `${year}-12-31`} // 🆕 Maximal erlaubt bis 31.12. des aktuellen Jahres
                onChange={(e) => setEndDate(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Anlass</Form.Label>
              <Form.Control as="select" value={anlass} onChange={(e) => setAnlass(e.target.value)}>
                <option>Urlaub</option>
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Kommentar</Form.Label>
              <Form.Control as="textarea" value={kommentar} onChange={(e) => setKommentar(e.target.value)} />
            </Form.Group>

            <Button variant="success" type="submit" className="mt-3">
              Antrag einreichen
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
