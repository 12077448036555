import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker, InfoWindow, Circle } from "@react-google-maps/api";
import { getDatabase, ref, onValue, set, remove, push } from "firebase/database";
import { Button, Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const API_KEY = process.env.REACT_APP_GOOGLE_KEY; // 🔹 API-Key aus .env.local laden

const iconColors = {
  0: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png", // Tankstelle
  1: "http://maps.google.com/mapfiles/ms/icons/green-dot.png", // Lade-Entlade Punkt
  2: "http://maps.google.com/mapfiles/ms/icons/red-dot.png", // Feierabend Zone
};

const circleColors = {
  0: "#0000FF", // Blau für Tankstelle
  1: "#008000", // Grün für Lade-Entlade Punkt
  2: "#FF0000", // Rot für Feierabend Zone
};

const containerStyle = {
  width: "100%",
  height: "100vh",
};

const center = {
  lat: 53.5,
  lng: 10.0,
};

const POIManager = () => {
  const [poiData, setPoiData] = useState({});
  const [selectedPOI, setSelectedPOI] = useState(null);
  const [show, setShow] = useState(false);
  const [editingPOI, setEditingPOI] = useState(null);
  const db = getDatabase();
  const FID = "Wg3168aR91TyFuceUT1eFJTsyVH3"; // 🔹 Setze hier dynamisch die FID

  useEffect(() => {
    if (!FID) return;
    const poiRef = ref(db, `/geo/${FID}`);
    onValue(poiRef, (snapshot) => {
      setPoiData(snapshot.val() || {});
    });
  }, [FID]);

  // Neue POI hinzufügen (durch Klick auf Karte)
  const handleAddPOI = (event) => {
    if (!FID) return;
    const newPOIRef = push(ref(db, `/geo/${FID}`));
    set(newPOIRef, {
      id: newPOIRef.key,
      bezeichnung: "Neuer POI",
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
      radius: 200,
      color: 0,
    });
  };

  // POI aktualisieren
  const handleUpdatePOI = (id, field, value) => {
    if (!FID) return;
    set(ref(db, `/geo/${FID}/${id}/${field}`), value);
  };

  // POI löschen
  const handleDeletePOI = (id) => {
    if (!FID) return;
    remove(ref(db, `/geo/${FID}/${id}`));
    setSelectedPOI(null);
  };

  // Modal öffnen zum Bearbeiten
  const handleEditPOI = (poi) => {
    setEditingPOI(poi);
    setShow(true);
  };

  // Änderungen speichern und Modal schließen
  const handleSaveChanges = () => {
    if (editingPOI) {
      handleUpdatePOI(editingPOI.id, "bezeichnung", editingPOI.bezeichnung);
      handleUpdatePOI(editingPOI.id, "radius", editingPOI.radius);
      handleUpdatePOI(editingPOI.id, "color", editingPOI.color);
    }
    setShow(false);
  };

  // Position durch Drag & Drop aktualisieren
  const handleMarkerDragEnd = (event, poi) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();

    // Neue Position in Firebase speichern
    handleUpdatePOI(poi.id, "latitude", newLat);
    handleUpdatePOI(poi.id, "longitude", newLng);

    // Zeige Info-Fenster mit der neuen Position an
    setSelectedPOI({ ...poi, latitude: newLat, longitude: newLng });
  };

  return (
    <LoadScript googleMapsApiKey={API_KEY}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={12}
        onClick={handleAddPOI} // Klick auf Karte fügt neuen POI hinzu
      >
        {/* Marker + Kreis für jeden POI */}
        {Object.keys(poiData).map((key) => {
          const poi = poiData[key];
          return (
            <React.Fragment key={poi.id}>
              {/* Marker mit Drag & Drop */}
              <Marker
                position={{ lat: poi.latitude, lng: poi.longitude }}
                icon={iconColors[poi.color]}
                draggable={true} // 🔹 Drag & Drop aktivieren
                onDragEnd={(event) => handleMarkerDragEnd(event, poi)} // 🔹 Neue Position speichern
                onClick={() => setSelectedPOI(poi)}
              />

              {/* Kreis um den POI */}
              <Circle
                center={{ lat: poi.latitude, lng: poi.longitude }}
                radius={poi.radius}
                options={{
                  strokeColor: circleColors[poi.color],
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: circleColors[poi.color],
                  fillOpacity: 0.2,
                }}
              />
            </React.Fragment>
          );
        })}

        {/* Info-Fenster für den ausgewählten POI */}
        {selectedPOI && (
          <InfoWindow
            position={{ lat: selectedPOI.latitude, lng: selectedPOI.longitude }}
            onCloseClick={() => setSelectedPOI(null)}
          >
            <div>
              <h6>{selectedPOI.bezeichnung}</h6>
              <p>Neue Position: {selectedPOI.latitude.toFixed(6)}, {selectedPOI.longitude.toFixed(6)}</p>
              <p>Radius: {selectedPOI.radius}m</p>
              <Button variant="primary" size="sm" onClick={() => handleEditPOI(selectedPOI)}>
                Bearbeiten
              </Button>
              <Button variant="danger" size="sm" className="mt-2" onClick={() => handleDeletePOI(selectedPOI.id)}>
                Löschen
              </Button>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>

      {/* Bootstrap Modal für Bearbeitung */}
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>POI bearbeiten</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editingPOI && (
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Bezeichnung</Form.Label>
                <Form.Control
                  type="text"
                  value={editingPOI.bezeichnung || ""}
                  onChange={(e) => setEditingPOI({ ...editingPOI, bezeichnung: e.target.value })}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Radius (Meter)</Form.Label>
                <Form.Control
                  type="number"
                  value={editingPOI.radius || 200}
                  onChange={(e) => setEditingPOI({ ...editingPOI, radius: Number(e.target.value) })}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Kategorie</Form.Label>
                <Form.Select
                  value={editingPOI.color || 0}
                  onChange={(e) => setEditingPOI({ ...editingPOI, color: Number(e.target.value) })}
                >
                  <option value="0">Tankstelle</option>
                  <option value="1">Lade-Entlade Punkt</option>
                  <option value="2">Feierabend Zone</option>
                </Form.Select>
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
    </LoadScript>
  );
};

export default POIManager;
