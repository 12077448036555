import React from "react";
import { Table, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";

function ExpiryDatesTab({ expiryData, handleDateChange }) {
  // Stile für die DatePicker-Felder (z.B. Ablaufdatum, Termin)
  const datePickerStyle = {
    minWidth: "120px",  // Platz für "dd.mm.yyyy"
    paddingRight: "2rem" // Raum für das X (Clear-Button)
  };

  // Spaltenüberschriften (TH) sollen eine Mindestbreite haben
  // und kein automatisches Zeilenumbruchverhalten
  const thStyle = {
    minWidth: "100px",
    whiteSpace: "nowrap"
  };

  // Spaltenzellen (TD) bekommen ebenso eine Mindestbreite
  // und bleiben in einer Zeile (nowrap)
  const tdStyle = {
    minWidth: "130px",
    whiteSpace: "nowrap"
  };

  return (
    // 1) Für horizontales Scrollen auf kleinen Screens:
    <div className="table-responsive">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={thStyle}>Feldname</th>
            <th style={thStyle}>Ablaufdatum</th>
            <th style={thStyle}>Termin</th>
            <th style={thStyle}>Intervall</th>
            <th style={thStyle}>Termin gesetzt</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(expiryData).map(([key, value]) => (
            <tr key={key}>
              {/* Spalte 1: Feldname */}
              <td style={tdStyle}>
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </td>

              {/* Spalte 2: Ablaufdatum */}
              <td style={tdStyle}>
                <DatePicker
                  className="w-100"
                  style={datePickerStyle}
                  locale="de"
                  selected={
                    // Wenn `value` ein Objekt ist und `expiryDate` hat:
                    typeof value === "object" && value.expiryDate
                      ? new Date(value.expiryDate)
                      // Wenn `value` direkt ein Zahlen-Timestamp > 0:
                      : typeof value === "number" && value > 0
                      ? new Date(value)
                      : null
                  }
                  onChange={(date) =>
                    handleDateChange(
                      key,
                      date,
                      // Wenn `value` ein Objekt mit `expiryDate` ist, updaten wir das
                      typeof value === "object" && value.expiryDate !== undefined
                        ? "expiryDate"
                        : null
                    )
                  }
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  scrollableYearDropdown
                  dateFormat="dd.MM.yyyy"
                  dropdownMode="select"
                  isClearable
                  placeholderText="Datum wählen"
                />
              </td>

              {/* Spalte 3: Termin (wenn actualAppointmentDate existiert) */}
              <td style={tdStyle}>
                {value.actualAppointmentDate !== undefined && (
                  <DatePicker
                    className="w-100"
                    style={datePickerStyle}
                    locale="de"
                    selected={
                      value.actualAppointmentDate
                        ? new Date(value.actualAppointmentDate)
                        : null
                    }
                    onChange={(date) => handleDateChange(key, date, "actualAppointmentDate")}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dateFormatCalendar="MMMM"
                    scrollableYearDropdown
                    dateFormat="dd.MM.yyyy"
                    dropdownMode="select"
                    isClearable
                    placeholderText="Termin wählen"
                  />
                )}
              </td>

              {/* Spalte 4: Intervall (wenn duedateinterval existiert) */}
              <td style={tdStyle}>
                {value.duedateinterval !== undefined && (
                  <Form.Control
                    type="number"
                    value={value.duedateinterval}
                    onChange={(e) =>
                      handleDateChange(
                        key,
                        parseInt(e.target.value, 10),
                        "duedateinterval"
                      )
                    }
                    placeholder="Intervall in Tagen"
                    style={{ minWidth: "70px" }}
                  />
                )}
              </td>

              {/* Spalte 5: Termin gesetzt? (CheckBox, wenn appointmentMade existiert) */}
              <td style={tdStyle}>
                {value.appointmentMade !== undefined && (
                  <Form.Check
                    type="checkbox"
                    checked={value.appointmentMade}
                    onChange={(e) =>
                      handleDateChange(key, e.target.checked, "appointmentMade")
                    }
                    label="Ja"
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default ExpiryDatesTab;
