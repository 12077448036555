import React, { useEffect, useState } from "react";
import { Card, ListGroup, Badge, Row, Col } from "react-bootstrap";
import { ref, getDatabase, onValue } from "firebase/database";

export function UrlaubInfo({
  FID,
  year,
  isAdmin,
  UID,
  CurrentUser,
  CurrentMitarbeiter,
}) {
  const db = getDatabase();
  const [urlaubsdaten, setUrlaubsDaten] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!FID || !year || !CurrentMitarbeiter) return;

    const urlaubRef = ref(db, `urlaube/${FID}/${year}`);
    const kalenderRef = ref(db, `kalender/${FID}/${year}`);

    // 🚀 Echtzeit-Listener für die Urlaubsübersicht
    const unsubscribeUrlaube = onValue(urlaubRef, (snapshot) => {
      if (!snapshot.exists()) {
        setUrlaubsDaten([]);
        return;
      }

      let urlaubDaten = Object.entries(snapshot.val());

      // 🚀 Falls kein Admin → Nur eigene Urlaube anzeigen
      if (!isAdmin) {
        urlaubDaten = urlaubDaten.filter(([userID]) => userID === UID);
      }

      // 🚀 Echtzeit-Listener für Urlaubsanträge
      onValue(kalenderRef, (kalenderSnapshot) => {
        let kalenderDaten = kalenderSnapshot.exists()
          ? kalenderSnapshot.val()
          : {};

        const response = urlaubDaten.map(([userID, userData]) => {
          const userKalender = kalenderDaten[userID] || {};

          // ✅ Alle Urlaubsanträge nach Status filtern
          const wartendeUrlaube = Object.values(userKalender).filter(
            (u) => u.status === "wartend"
          );
          const genehmigteUrlaube = Object.values(userKalender).filter(
            (u) => u.status === "genehmigt"
          );
          const krankheitsTage = Object.values(userKalender).filter(
            (u) => u.anlass === "Krankheit"
          );

          const beantragteTage = wartendeUrlaube.reduce(
            (sum, u) => sum + u.anzahltage,
            0
          );
          const genommeneTage = genehmigteUrlaube.reduce(
            (sum, u) => sum + u.anzahltage,
            0
          );
          const krankheitstage = krankheitsTage.reduce(
            (sum, u) => sum + u.anzahltage,
            0
          );

          const gesamtUrlaubJahr =
            userData.anspruch + userData.rest + userData.sonderurlaub;
          const restUrlaubJahr =
            gesamtUrlaubJahr - (genommeneTage + beantragteTage);

          return {
            id: userID,
            year: year,
            name: CurrentMitarbeiter[userID]?.name || "Unbekannt",
            color: CurrentMitarbeiter[userID]?.color || "#000",
            anspruch: userData.anspruch,
            rest: userData.rest,
            sonderurlaub: userData.sonderurlaub,
            gesamtUrlaubJahr,
            genommeneTage,
            beantragteTage,
            restUrlaubJahr,
            krankheitstage,
            anzahlUrlaube: genehmigteUrlaube.length,
          };
        });

        setUrlaubsDaten(response);
        setIsLoading(false);
      });
    });

    return () => {
      unsubscribeUrlaube();
    };
  }, [FID, year, isAdmin, UID, CurrentUser, CurrentMitarbeiter]);

  // Funktion zur Bestimmung der Kontrastfarbe
  function getContrastColor(hexColor) {
    if (!hexColor) return "#000"; // Falls keine Farbe vorhanden ist, Standard: Schwarz

    // Hex in RGB umwandeln
    const r = parseInt(hexColor.substring(1, 3), 16);
    const g = parseInt(hexColor.substring(3, 5), 16);
    const b = parseInt(hexColor.substring(5, 7), 16);

    // Helligkeit berechnen (W3C Formel)
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Wenn die Helligkeit über 128 ist, nutze schwarze Schrift, sonst weiße
    return brightness > 128 ? "#000000" : "#FFFFFF";
  }

  return (
    <React.Fragment>
      <Row>
        {urlaubsdaten.map((userurlaub) => (
          <Col
            key={userurlaub.id}
            className={
              urlaubsdaten.length > 1
                ? "mt-2 col-md-12 col-lg-12 col-xl-6"
                : "mt-2 col-md-12 col-lg-12 col-xl-12"
            }
          >
            <Card
              style={{ borderColor: userurlaub.color, borderRadius: "30px" }}
            >
              <Card.Header
                style={{
                  fontWeight: "bold",
                  backgroundColor: userurlaub.color,
                  color: getContrastColor(userurlaub.color), // Dynamische Schriftfarbe
                  borderTopLeftRadius: "30px",
                  borderTopRightRadius: "30px",
                }}
              >
                <div className="text-center">{userurlaub.name}</div>
              </Card.Header>

              <ListGroup variant="flush">
                {[
                  {
                    label: "Rest von Letztem Jahr",
                    value: userurlaub.rest,
                    color: "primary",
                  },
                  {
                    label: "Urlaubsanspruch",
                    value: userurlaub.anspruch,
                    color: "primary",
                  },
                  {
                    label: "Sonderurlaub",
                    value: userurlaub.sonderurlaub,
                    color: "primary",
                  },
                  {
                    label: "Summe für dieses Jahr",
                    value: userurlaub.gesamtUrlaubJahr,
                    color: "primary",
                  },
                  {
                    label: "Genommene Urlaubstage",
                    value: userurlaub.genommeneTage,
                    color: "warning",
                  },
                  {
                    label: "Beantragte Urlaubstage",
                    value: userurlaub.beantragteTage,
                    color: "info",
                  },
                  {
                    label: "Verbleibende Urlaubstage",
                    value: userurlaub.restUrlaubJahr,
                    color: "success",
                  },
                  {
                    label: "Krankheitstage",
                    value: userurlaub.krankheitstage,
                    color: "danger",
                  },
                ]
                  .filter(Boolean)
                  .map(({ label, value, color }) => (
                    <ListGroup.Item
                      key={label}
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <small>{label}</small>
                      <Badge bg={color} style={{ width: "50px" }}>
                        {value}
                      </Badge>
                    </ListGroup.Item>
                  ))}
              </ListGroup>

              <Card.Footer
                className="text-muted"
                style={{
                  fontWeight: "bold",
                  backgroundColor: userurlaub.uid?.color,
                  borderBottomRightRadius: "30px",
                  borderBottomLeftRadius: "30px",
                }}
              >
                <small>Gesamt Urlaube: {userurlaub.anzahlUrlaube}</small>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
}
