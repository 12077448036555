import React, { useState } from "react";
import useLoadStopps from "../hooks/useLoadStopps";
import { Spinner, Table } from "react-bootstrap";
import moment from "moment";

const StoppsListe = ({ userID }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { stopps, loading, error } = useLoadStopps(selectedDate, userID);

  const formatDate = (date) => {
    return new Intl.DateTimeFormat("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(date);
  };

  const getArtColor = (art) => {
    switch (art) {
      case "vergeblich":
        return "red";
      case "erfolgreich":
        return "green";
      case "abholer":
        return "blue";
      default:
        return "black";
    }
  };

  return (
    <div className="container">
      <h3>Stopps am {formatDate(selectedDate)}</h3>

      {loading ? (
        <Spinner animation="border" />
      ) : error ? (
        <p className="text-danger">{error}</p>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Uhrzeit</th> {/* Neue Spalte für die formatierte Zeit */}
              <th>Adresse</th>
              <th>Art</th>
              <th>Distanz (m)</th>
              <th>Zeit (s)</th>
             
            </tr>
          </thead>
          <tbody>
            {stopps.length > 0 ? (
              stopps.map((stopp) => (
                <tr key={stopp.id}>
                <td>{moment(stopp.createdatetime).format("HH:mm:ss")}</td> {/* Formatierte Zeit */}
                  <td>{stopp.adress.replace(/\n/g, ", ")}</td>
                  <td style={{ color: getArtColor(stopp.art) }}>{stopp.art}</td>
                  <td>{stopp.distance}</td>
                  <td>{stopp.elapsedTime}</td>
                  
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">Keine Stopps gefunden.</td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default StoppsListe;
