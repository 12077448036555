import React, { useState } from "react";
import { useAuth } from "../../../context/authContext";
import { Urlaubskalender } from "./Urlaubskalender";
import { UrlaubInfo } from "./UrlaubInfo";
import { UrlaubBeantragen } from "./UrlaubBeantragen";
import { KrankheitEintragen } from "./KrankheitEintragen";
import { Container, Form, Row, Col } from "react-bootstrap";

export default function Kalender() {
  const { FID, UID, isAdmin, currentUserDetail, currentMitarbeiter } = useAuth();
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  return (
    <Container className="mt-3">
      <h1>Urlaubskalender</h1>

      {/* 🆕 Jahr auswählen */}
      <Form.Group className="mb-3">
        <Form.Label>Jahr auswählen</Form.Label>
        <Form.Select
          value={selectedYear}
          onChange={(e) => setSelectedYear(Number(e.target.value))}
        >
          {[...Array(10)].map((_, i) => {
            const year = currentYear - 5 + i; // Zeigt 5 Jahre zurück und 5 Jahre in die Zukunft
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>

      <Row>
        {/* 🆕 Linke Spalte: UrlaubInfo (nimmt 6 von 12 Spalten ein) */}
        <Col className="mb-3" md={6}>
          <h2>UrlaubInfo</h2>
          <UrlaubInfo
            FID={FID}
            year={selectedYear}
            isAdmin={isAdmin}
            UID={UID}
            CurrentUser={currentUserDetail}
            CurrentMitarbeiter={currentMitarbeiter}
          />

          {/* 🆕 Urlaub beantragen (unter UrlaubInfo) */}
          <h2 className="mt-3">Urlaub beantragen</h2>
          <UrlaubBeantragen
            FID={FID}
            year={selectedYear}
            UID={UID}
            CurrentUser={currentUserDetail}
          />

          {/* 🆕 Krankheit eintragen (unter Urlaub beantragen, nur für Admins) */}
          {isAdmin && (
            <>
              <h2 className="mt-3">Krankheit eintragen</h2>
              <KrankheitEintragen
                FID={FID}
                year={selectedYear}
                CurrentMitarbeiter={currentMitarbeiter}
              />
            </>
          )}
        </Col>

        {/* 🆕 Rechte Spalte: Urlaubskalender (nimmt 6 von 12 Spalten ein) */}
        <Col className="mb-3" md={6}>
          <h2>Urlaubskalender</h2>
          <Urlaubskalender
            FID={FID}
            year={selectedYear}
            isAdmin={isAdmin}
            UID={UID}
            CurrentMitarbeiter={currentMitarbeiter}
          />
        </Col>
      </Row>
    </Container>
  );
}
