import React from "react";
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import gesterlingKopf from "../../images/kopfzeile.png";
import gesterlingFuss from "../../images/Fusszeile.png";
import moment from "moment";
import "moment/locale/de"; // Deutsche Sprache aktivieren

// Stile für die PDF-Datei
const styles = StyleSheet.create({
  body: { paddingTop: 15, paddingBottom: 65, paddingHorizontal: 35 },
  header: { fontSize: 18, marginBottom: 10, textAlign: "center", fontWeight: "bold" },
  subHeader: { fontSize: 12, marginBottom: 5, textAlign: "left", fontWeight: "bold" },
  address: { fontSize: 10, marginBottom: 10, textAlign: "left" },
  table: { display: "table", width: "auto", marginTop: 10, borderStyle: "solid", borderWidth: 1 },
  tableRow: { flexDirection: "row" },
  tableColHeader: { width: "16.6%", borderStyle: "solid", borderWidth: 1, backgroundColor: "#ADD8E6", padding: 5, fontSize: 10, textAlign: "center", fontWeight: "bold" },
  tableCol: { width: "16.6%", borderStyle: "solid", borderWidth: 1, padding: 5, fontSize: 10, textAlign: "center" },
  summaryRow: { flexDirection: "row", marginTop: 10, fontWeight: "bold", fontSize: 12, borderTopWidth: 1, paddingTop: 5 },
  image: { width: "100%", height: 50 },
  footer: { position: "absolute", bottom: 10, left: 0, right: 0, width: "100%" },
});

// Datum- und Zeitformatierung
const formatDate = (timestamp) => new Intl.DateTimeFormat("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" }).format(timestamp);
const formatTime = (timestamp) => new Intl.DateTimeFormat("de-DE", { hour: "2-digit", minute: "2-digit", timeZone: "Europe/Berlin" }).format(timestamp);
const formatMinutes = (milliseconds) => `${milliseconds / 60000} min`;
const currencyNumberFormat = (value) => new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(value);
const formatHours = (milliseconds) => {
  const hours = Math.floor(milliseconds / 3600000);
  const minutes = Math.floor((milliseconds % 3600000) / 60000);
  return `${hours}:${minutes.toString().padStart(2, "0")} Std.`;
};

const BasicDocument = ({ data = {}, userinfo = [] }) => {
  if (!data || Object.keys(data).length === 0) {
    return <Text>Keine Spesen vorhanden.</Text>;
  }
  console.log("DATA->",data); 
  // Benutzerinformationen in ein Objekt umwandeln für schnellen Zugriff
  const userMap = userinfo.reduce((acc, user) => {
    acc[user.id] = user;
    return acc;
  }, {});

  return (
    <Document>
      {Object.entries(data).map(([userId, userEntries]) => {
        const user = userMap[userId] || {}; // Benutzerinformationen holen
        const totalArbeitszeit = userEntries.reduce((sum, entry) => sum + entry.stunden, 0);
        const totalSpesen = userEntries.reduce((sum, entry) => sum + entry.spesen, 0);
        
        // Monat und Jahr auf Deutsch formatieren
        const monthYear = moment(userEntries[0].datum).locale("de").format("MMMM YYYY");

        return (
          <Page size="A4" style={styles.body} key={userId}>
            <Image style={styles.image} src={gesterlingKopf} />
            <Text style={styles.header}>Spesenabrechnung für {monthYear}</Text>

            {/* Adressblock */}
            <Text style={styles.subHeader}>{user.name || "Unbekannter Mitarbeiter"}</Text>
            <Text style={styles.address}>{user.strasse || "Straße nicht verfügbar"}</Text>
            <Text style={styles.address}>{user.plz && user.ort ? `${user.plz} ${user.ort}` : "Ort nicht verfügbar"}</Text>
           

            {/* Tabelle */}
            <View style={styles.table}>
              {/* Tabellen-Header */}
              <View style={styles.tableRow}>
                <Text style={styles.tableColHeader}>Datum</Text>
                <Text style={styles.tableColHeader}>Arbeitsbeginn</Text>
                <Text style={styles.tableColHeader}>Pause</Text>
                <Text style={styles.tableColHeader}>Feierabend</Text>
                <Text style={styles.tableColHeader}>Arbeitsstunden</Text>
                <Text style={styles.tableColHeader}>Spesen (€)</Text>
              </View>

              {/* Tabellen-Daten */}
              {userEntries.map((entry, index) => (
                <View style={styles.tableRow} key={index}>
                  <Text style={styles.tableCol}>{formatDate(entry.datum)}</Text>
                  <Text style={styles.tableCol}>{formatTime(entry.beginn)}</Text>
                  <Text style={styles.tableCol}>{formatMinutes(entry.pause)}</Text>
                  <Text style={styles.tableCol}>{formatTime(entry.feierabend)}</Text>
                  <Text style={styles.tableCol}>{formatHours(entry.stunden)}</Text>
                  <Text style={styles.tableCol}>{currencyNumberFormat(entry.spesen)}</Text>
                </View>
              ))}
            </View>

            {/* Gesamtübersicht */}
            <View style={styles.summaryRow}>
              <Text style={{ width: "66%", textAlign: "right", paddingRight: 10 }}>Gesamtarbeitszeit:</Text>
              <Text style={{ width: "16.6%", textAlign: "center" }}>{formatHours(totalArbeitszeit)}</Text>
              <Text style={{ width: "16.6%", textAlign: "center" }}>{currencyNumberFormat(totalSpesen)}</Text>
            </View>

            <Image style={styles.footer} src={gesterlingFuss} />
          </Page>
        );
      })}
    </Document>
  );
};

export default BasicDocument;
