import React from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { SliderPicker } from "react-color";
import Select from "react-select";

function SpecificDataTab({ data, setFormData, options }) {
  return (
    <>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="validationf">
          <InputGroup.Text style={{ backgroundColor: data.color }}>
            Mitarbeiter Farbe: {data.color}
          </InputGroup.Text>
          <SliderPicker
            color={data.color || ""}
            onChangeComplete={(e) =>
              setFormData({ ...data, color: e.hex })
            }
          />
        </Form.Group>

        <Form.Group as={Col} md="6" controlId="validationr">
          <InputGroup.Text>Rollen des Mitarbeiters</InputGroup.Text>
          <Select
            isMulti
            options={options} // ✅ Hier wird options jetzt verwendet
            value={Array.isArray(data.rollen) ? data.rollen : []} // ✅ Sicherstellen, dass rollen ein Array ist
            onChange={(selectedOptions) => setFormData({ ...data, rollen: selectedOptions })}
          />
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} md="3" controlId="validationisMitarbeiterAktiv">
          <Form.Check
            label="Ist der Mitarbeiter Aktiv"
            checked={data.isAktiv && !data.isDelete}
            disabled={data.isDelete}
            onChange={(e) =>
              setFormData({ ...data, isAktiv: e.target.checked })
            }
          />
        </Form.Group>

        <Form.Group as={Col} md="3" controlId="validationisGeloescht">
          <Form.Check
            label="Mitarbeiter gelöscht?"
            checked={data.isDelete || ""}
            onChange={(e) =>
              setFormData({ ...data, isDelete: e.target.checked })
            }
          />
        </Form.Group>

        <Form.Group as={Col} md="3" controlId="validationisLohnabrechnungen">
          <Form.Check
            label="Bekommt Lohnabrechnungen"
            checked={data.isLohnabrechnungen || ""}
            onChange={(e) =>
              setFormData({
                ...data,
                isLohnabrechnungen: e.target.checked,
              })
            }
          />
        </Form.Group>

        <Form.Group as={Col} md="3" controlId="validationisSpesen">
          <Form.Check
            label="Bekommt Spesen"
            checked={data.isSpesen || ""}
            onChange={(e) =>
              setFormData({ ...data, isSpesen: e.target.checked })
            }
          />
        </Form.Group>
      </Row>

      <Form.Group as={Col} md="6" controlId="validationPathLohn">
        <Form.Label>Pfad zu Lohnabrechnungen</Form.Label>
        <Form.Control
          type="text"
          disabled={!data.isLohnabrechnungen}
          placeholder="Pfad zu Lohnabrechnungen"
          value={data.pathlohn || ""}
          onChange={(event) =>
            setFormData({ ...data, pathlohn: event.target.value })
          }
        />
      </Form.Group>
    </>
  );
}

export default SpecificDataTab;
