import React, { useState, useEffect } from "react";
import {   
      ref,  
      getDatabase,
      onValue,         
    } from "firebase/database";
import { useAuth } from "../../../context/authContext";
import { Card, Container, Row, Col, Badge } from "react-bootstrap";





const UserStoppsCounter = () => {
  const [successfulCount, setSuccessfulCount] = useState(0);
  const [unsuccessfulCount, setUnsuccessfulCount] = useState(0);
  const [dateRange, setDateRange] = useState("");

  const [UID, setUID] = useState("");
  const db = getDatabase();
  const [FID, setFID] = useState("");
  const { currentUser } = useAuth();
   
   
    useEffect(() => {
        if (currentUser) {
            setFID(currentUser.photoURL);
            setUID(currentUser.uid);
        }
    }, [currentUser]);




    useEffect(() => {
      if (FID && UID && db) {
        const stoppsRef  = ref(db,"dailybusiness/" + FID);  
        onValue(stoppsRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            let successful = 0;
            let unsuccessful = 0;
            let timestamps = [];
    
            // Über alle Tage iterieren (Timestamps als Schlüssel)
            for (const dayKey in data) {
              const dayData = data[dayKey]?.stopps;
    
              if (dayData) {
                timestamps.push(parseInt(dayKey, 10)); // Sammle die Timestamps
                // Stopps an diesem Tag zählen
                for (const stoppId in dayData) {
                  const stopp = dayData[stoppId];
                  if (stopp.userid === UID) {
                    if (stopp.art === "erfolgreich") {
                      successful++;
                    } else {
                      unsuccessful++;
                    }
                  }
                }
              }
            }
          // Zeitraum berechnen
          if (timestamps.length > 0) {
            const startDate = new Date(Math.min(...timestamps)).toLocaleDateString();
            const endDate = new Date(Math.max(...timestamps)).toLocaleDateString();
            setDateRange(`${startDate} - ${endDate}`);
          } else {
            setDateRange("Keine Daten verfügbar");
          }
            setSuccessfulCount(successful);
            setUnsuccessfulCount(unsuccessful);
          } else {
            console.log("Keine Daten in der Datenbank gefunden.");
            setDateRange("Keine Daten verfügbar");
            setSuccessfulCount(0);
            setUnsuccessfulCount(0);
          }
        });
      }
    }, [UID , FID ,db]);
  
    return (
      <Container className="mt-5">
        <Row className="justify-content-center mt-4">
          <Col md={10} lg={8}>
            <Card className="shadow-lg border-0 rounded-lg">
              <Card.Header className="bg-primary text-white py-3">
                <h3 className="mb-0 text-center">Stopps-Übersicht</h3>
                <Badge bg="light" text="dark">
                  Zeitraum: {dateRange}
                </Badge>
              </Card.Header>
              <Card.Body className="bg-light">
                <Row>
                  <Col sm={6}>
                    <Card className="border-0 shadow-sm mb-3 h-100">
                      <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                        <i className="bi bi-check-circle text-success mb-3" size={48} />                        
                        <h4 className="text-success">Erfolgreiche Stopps</h4>
                        <h2 className="display-4 font-weight-bold">
                          {successfulCount}
                        </h2>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={6}>
                    <Card className="border-0 shadow-sm mb-3 h-100">
                      <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                      <i className="bi bi-x-circle text-success mb-3" size={48}></i>    
                       
                        <h4 className="text-danger">Nicht erfolgreiche Stopps</h4>
                        <h2 className="display-4 font-weight-bold">
                          {unsuccessfulCount}
                        </h2>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  };
  
  export default UserStoppsCounter;