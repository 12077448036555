import React, { useEffect, useMemo,useState  } from "react";
import { Card, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import UserStoppsCounter from "../pages/userstopps/UserStoppsCounter";
import PalettenKonto from "../pages/palettenkonto/PalettenKonto";

export const Dashbord = () => {
  const navigate = useNavigate();  
  const { currentUser, currentFirma, currentUserDetail, currentMitarbeiter,isAdmin } = useAuth();  
  // ✅ `useMemo` zur Optimierung der Ladeüberprüfung
  const isLoading = useMemo(() => {
    if (typeof isAdmin === "undefined") return true; // Warte auf Daten
    if (isAdmin) return false; // Admins haben immer Zugriff
    return !(currentFirma && currentUserDetail && currentMitarbeiter);
  }, [isAdmin, currentFirma, currentUserDetail, currentMitarbeiter]);

  useEffect(() => {
    if (typeof currentUser === "undefined") return; // Warte auf Ladevorgang
  
    if (!currentUser) {
      navigate("/login", { replace: true });
    }
  }, [currentUser, navigate]);
  
  useEffect(() => {
    console.log("Admin Status geladen:", isAdmin);
  }, [isAdmin]);

  return (
  <>
    {isLoading ? (
      <div className="loading-container d-flex justify-content-center align-items-center">
        <p>Lade Dashboard...</p>
      </div>
    ) : (
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Dashboard</h2>
          <Row className="justify-content-center mt-4">
            <Col md={6}>
              <UserStoppsCounter />
            </Col>
            <Col md={6}>
              <PalettenKonto />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )}
  </>
);

}
export default Dashbord;