import React from "react";
import { useAuth } from "./context/authContext";
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from "react-router-dom";
import Popup from "./components/Popup";
import "./App.css";
import { NoMatch } from "./components/pages/notFound/notfound";
import { MyNavbar } from "./components/navbar";
import Login from "./components/pages/login";
import Register from "./components/pages/register";
import { Dashbord } from "./components/pages/Dashbord";
import Services from "./components/pages/Services";
import ContactUs from "./components/pages/ContactUs";
import Urlaube from "./components/pages/Urlaube/Urlaube";
import { Datenschutz, Impressum } from "./components/pages/rechliches/rechtliches";
import { Mitarbeiter } from "./components/pages/mitarbeiter/Mitarbeiter";
import Rollen from "./components/pages/Rollen";
import { Stopps } from "./components/pages/stoppsauswertung/stopps";
import  Spesen  from "./components/pages/spesen/spesen";
import { Live } from "./components/pages/live/live";
import { Anwesenheit } from "./components/pages/anwesenheit/anwesenheit";
import LiveMap from "./components/pages/live/gMaps";
import { Fahrzeuge } from "./components/pages/Fahrzeuge/Fahrzeuge";
import { Notice } from "./components/pages/Notice/Notice";
import { Touren } from "./components/pages/Touren/Touren";
import { Verguetung } from "./components/pages/Verguetung/Verguetung";
import Kalender from "./components/pages/Kalender/Kalender";
import ForgotPassword from "./components/pages/forgotPassword";
import PalettenKonto from "./components/pages/palettenkonto/PalettenKonto";
import Abgemeldet from "./components/pages/abgemeldet";
import { Tankungen } from "./components/pages/Tankungen/tankungen";
import TankungenDetail from "./components/pages/Tankungen/TankungenDetail";
import GoogleMapComponent from "./components/pages/regionen/GoogleMapComponent";
import MitarbeiterDashboard from "./components/pages/dashboard/MitarbeiterDashboard";
import StoppsListe from "./components/pages/stopps/StoppsListe";
import { Urlaubskalender } from "./components/pages/Kalender/Urlaubskalender";
import UrlaubsdatenVerwalten from "./components/pages/Kalender/UrlaubsdatenVerwalten";
import POIManager from "./components/pages/POI/POIManager";


/** 🔹 Geschützte Route für angemeldete Benutzer */
const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();
  return currentUser ? children : <Navigate to="/login" replace />;
};

/** 🔹 Geschützte Admin-Route */
const AdminRoute = ({ children }) => {
  const { isAdmin, currentUser } = useAuth();
  return currentUser && isAdmin ? children : <Navigate to="/" replace />;
};

const App = () => {
  const { currentUser, isAdmin, loading , FID , currentMitarbeiter , UID} = useAuth();

  // 🔹 Ladebildschirm während Auth-Daten geladen werden
  if (loading) {
    return (
      <div className="loading-container d-flex justify-content-center align-items-center">
        <p>Lade Anwendung...</p>
      </div>
    );
  }

  return (
    <Router>
      <div className="App">
        <Popup />
        {currentUser ? <MyNavbar /> : <Outlet />}

        <div className="content">
          <Routes>
            {/* 🔹 Dynamische Landing Page basierend auf Rolle */}
            <Route path="/" element={isAdmin ? <Live /> : <MitarbeiterDashboard />} />

            {/* 🔹 Öffentliche Seiten */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/abgemeldet" element={<Abgemeldet />} />

            {/* 🔹 Geschützte Benutzer-Seiten */}
            <Route path="/dashboard" element={<ProtectedRoute><Dashbord /></ProtectedRoute>} />
            <Route path="/mitarbeiterDashboard" element={<ProtectedRoute><MitarbeiterDashboard /></ProtectedRoute>} />
            <Route path="/services" element={<ProtectedRoute><Services /></ProtectedRoute>} />
            <Route path="/kalender" element={<ProtectedRoute><Kalender /></ProtectedRoute>} />
            <Route path="/urlaubskalender" element={<ProtectedRoute><Urlaubskalender /></ProtectedRoute>} />
            <Route path="/urlaubsdaten" element={<ProtectedRoute><UrlaubsdatenVerwalten FID={FID} CurrentMitarbeiter={currentMitarbeiter} /></ProtectedRoute>} />           
            <Route path="/spesen" element={<ProtectedRoute><Spesen /></ProtectedRoute>} />            
            <Route path="/urlaube" element={<ProtectedRoute><Urlaube /></ProtectedRoute>} />
            <Route path="/tankungen" element={<ProtectedRoute><Tankungen /></ProtectedRoute>} />
            <Route path="/tankungen/detail" element={<ProtectedRoute><TankungenDetail /></ProtectedRoute>} />
            <Route path="/stopps" element={<ProtectedRoute><StoppsListe  /></ProtectedRoute>} />
            <Route path="/notice" element={<ProtectedRoute><Notice FID={FID} UID={UID} CurrentUser={currentUser} /></ProtectedRoute>} />

            {/* 🔹 Geschützte Admin-Seiten */}
            <Route path="/mitarbeiter" element={<AdminRoute><Mitarbeiter /></AdminRoute>} />
            <Route path="/fahrzeuge" element={<AdminRoute><Fahrzeuge /></AdminRoute>} />
            <Route path="/touren" element={<AdminRoute><Touren /></AdminRoute>} />
            <Route path="/verguetung" element={<AdminRoute><Verguetung /></AdminRoute>} />
            <Route path="/stoppsauswertung" element={<AdminRoute><Stopps /></AdminRoute>} />
            <Route path="/anwesenheit" element={<AdminRoute><Anwesenheit /></AdminRoute>} />
            <Route path="/live" element={<AdminRoute><Live /></AdminRoute>} />
            <Route path="/liveMap" element={<AdminRoute><LiveMap /></AdminRoute>} />           
            <Route path="/rollen" element={<AdminRoute><Rollen /></AdminRoute>} />
            <Route path="/POIManager" element={<AdminRoute><POIManager /></AdminRoute>} />
            <Route path="/diagramme" element={<AdminRoute><GoogleMapComponent /></AdminRoute>} />
            <Route path="/palettenKonto" element={<AdminRoute><PalettenKonto /></AdminRoute>} />

            {/* 🔹 Fallback-Route für 404-Seiten */}
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
