import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Card,
  Button,
  Alert,
  Container,
  Col,
  Navbar,
  InputGroup
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuth  } from "../../context/authContext";
import "bootstrap-icons/font/bootstrap-icons.css"; // ✅ Bootstrap Icons importieren




function Login() {
  const locksign = <i className="bi bi-lock"></i>;
  const cardStyle = {
    backgroundColor: "rgb(255, 255, 255)",
    borderRadius: "8px",
    boxShadow: "0 0 15px 1px rgba(0, 0, 0, 0.4)",
  };

  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const { login, currentUser , loading} = useAuth();
  const [error, setError] = useState("");
 
  const navigate = useNavigate();

  useEffect(() => {   
    if (currentUser){
      navigate("/");
    } 
  },[currentUser, navigate]);


  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
   //   setLoding(true);
      setError("");
      await login(emailRef.current.value, passwordRef.current.value);
   

    } catch (error) {
      setError("Fehler beim Einloggen! " + error.message);
    } finally {
   //   setLoding(false);
    }
  };

  return (
    <>
      <Navbar variant="dark" bg="dark" >
        <Container>
          <Navbar.Brand className="mx-auto" style={{ fontSize: 22 }}>
          Service-Subunternehmer
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container fluid>
        <Col xs={12} sm={12} md={6} lg={5} className="mx-auto">
          <Card className="mt-3 bg-light align-items-stretch" style={cardStyle}>
            <Card.Body>
              <h2 className="text-center mb-4">Bitte melden Sie sich an</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group id="email">
                  <Form.Label>Email</Form.Label>
                  <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                        @
                      </InputGroup.Text>
                  <Form.Control
                    type="email"
                    required
                    ref={emailRef}
                    placeholder="E-Mail Adresse eingeben"
                    autoComplete="username"
                  ></Form.Control>
                  </InputGroup>
                </Form.Group>
                <Form.Group id="password">
                  <Form.Label>Passwort</Form.Label>
                  <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                  {locksign}
                      </InputGroup.Text>
                  <Form.Control
                    type="password"
                    required
                    ref={passwordRef}
                    placeholder="Passwort eingeben"
                    autoComplete="current-password"
                  ></Form.Control>
                  </InputGroup>
                </Form.Group>
                <Button
                  type="submit"
                  className="w-100 mt-3"
                  variant="outline-success"
                  disabled={loading}
                >
                  Einloggen
                </Button>
              </Form>
              <div className="text-center w-100 mt-3">
                <Link to="/forgotPassword">Password Vergessen?</Link>
              </div>
            </Card.Body>
          </Card>
          <div className="d-flex justify-content-around">
          <div className="text-center w-100 mt-2">
            <Link to="/impressum">Impressum</Link>
          </div>
          <div className="text-center w-100 mt-2">
            <Link to="/register">Registrieren?</Link>
          </div>
          <div className="text-center w-100 mt-2">
            <Link to="/datenschutz">Datenschutz</Link>
          </div>

          </div>
          

        </Col>
      </Container>
    </>
  );
}
export default Login;
