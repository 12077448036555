import React from "react";
import { useAuth } from "../../../context/authContext";
import usePalettenKontoData from "../palettenkonto/usePalettenKontoData";
import { Card, Container, Tabs, Tab } from "react-bootstrap";

const PalettenKonto = () => {
  const { FID, currentMitarbeiter } = useAuth();
  const { summaries, isLoading, error } = usePalettenKontoData(FID);

  if (isLoading) return <div>Lade Daten...</div>;
  if (error) return <div style={{ color: "red" }}>{error}</div>;

  // 1) Aufteilen in Mitarbeiter-Einträge vs. Tour-Einträge
  const userSummaries = summaries.filter(
    (entry) => currentMitarbeiter[entry.name] !== undefined
  );
  const tourSummaries = summaries.filter(
    (entry) => currentMitarbeiter[entry.name] === undefined
  );

  // 2) Hilfsfunktion zum Berechnen der "Gesamt"-Werte einer Liste
  const computeTotals = (list) => {
    return list.reduce(
      (acc, curr) => {
        acc.totalTagessaldoFP += curr.totalTagessaldoFP;
        acc.totalTagessaldoGP += curr.totalTagessaldoGP;
        acc.totalLeergutAbholungFP += curr.totalLeergutAbholungFP;
        acc.totalLeergutAbholungGP += curr.totalLeergutAbholungGP;
        acc.diffFP += curr.diffFP;
        acc.diffGP += curr.diffGP;
        return acc;
      },
      {
        totalTagessaldoFP: 0,
        totalTagessaldoGP: 0,
        totalLeergutAbholungFP: 0,
        totalLeergutAbholungGP: 0,
        diffFP: 0,
        diffGP: 0,
      }
    );
  };

  const userTotals = computeTotals(userSummaries);
  const tourTotals = computeTotals(tourSummaries);

  // 3) Hilfsfunktion zum Rendern einer Tabelle
  //    (mit einer optionalen "Gesamt"-Zeile am Ende)
  const renderTable = (dataList, totals) => {
    return (
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid #ccc", textAlign: 'center'}}>Name/Tour</th>
            <th style={{ border: "1px solid #ccc", textAlign: 'center' }}>Tagessaldo FP</th>
            <th style={{ border: "1px solid #ccc", textAlign: 'center' }}>Tagessaldo GP</th>
            <th style={{ border: "1px solid #ccc", textAlign: 'center' }}>Leergutabholung FP</th>
            <th style={{ border: "1px solid #ccc", textAlign: 'center' }}>Leergutabholung GP</th>
            <th style={{ border: "1px solid #ccc", textAlign: 'center' }}>Differenz FP</th>
            <th style={{ border: "1px solid #ccc", textAlign: 'center' }}>Differenz GP</th>
          </tr>
        </thead>
        <tbody>
          {dataList.map((entry) => {
            const {
              name,
              totalTagessaldoFP,
              totalTagessaldoGP,
              totalLeergutAbholungFP,
              totalLeergutAbholungGP,
              diffFP,
              diffGP,
            } = entry;

            // Name entweder aus Mitarbeiter-Objekt oder Tournummer
            const mitarbeiter = currentMitarbeiter[name];
            const displayName = mitarbeiter ? mitarbeiter.name : name;

            const diffFPColor = diffFP > 1 ? "red" : "green";
            const diffGPColor = diffGP > 1 ? "red" : "green";

            return (
              <tr key={name}>
                <td style={{ border: "1px solid #ccc", padding: "4px" }}>
                  {displayName}
                </td>
                <td style={{ border: "1px solid #ccc", padding: "4px" }}>
                  {totalTagessaldoFP}
                </td>
                <td style={{ border: "1px solid #ccc", padding: "4px" }}>
                  {totalTagessaldoGP}
                </td>
                <td style={{ border: "1px solid #ccc", padding: "4px" }}>
                  {totalLeergutAbholungFP}
                </td>
                <td style={{ border: "1px solid #ccc", padding: "4px" }}>
                  {totalLeergutAbholungGP}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    border: "1px solid #ccc",
                    padding: "4px",
                    color: diffFPColor,
                  }}
                >
                  {diffFP}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    border: "1px solid #ccc",
                    padding: "4px",
                    color: diffGPColor,
                  }}
                >
                  {diffGP}
                </td>
              </tr>
            );
          })}

          {/* Gesamt-Zeile */}
          {dataList.length > 0 && (
            <tr style={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}>
              <td style={{ border: "1px solid #ccc", padding: "4px" }}>
                Gesamt
              </td>
              <td style={{ border: "1px solid #ccc", padding: "4px" }}>
                {totals.totalTagessaldoFP}
              </td>
              <td style={{ border: "1px solid #ccc", padding: "4px" }}>
                {totals.totalTagessaldoGP}
              </td>
              <td style={{ border: "1px solid #ccc", padding: "4px" }}>
                {totals.totalLeergutAbholungFP}
              </td>
              <td style={{ border: "1px solid #ccc", padding: "4px" }}>
                {totals.totalLeergutAbholungGP}
              </td>
              <td
                style={{
                  border: "1px solid #ccc",
                  padding: "4px",
                  color: totals.diffFP > 1 ? "red" : "green",
                }}
              >
                {totals.diffFP}
              </td>
              <td
                style={{
                  border: "1px solid #ccc",
                  padding: "4px",
                  color: totals.diffGP > 1 ? "red" : "green",
                }}
              >
                {totals.diffGP}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  return (
    <Container>
      <Card className="mt-3 bg-light align-items-stretch myCard">
      <Card.Header className="bg-primary text-white py-3">
        <h4 className="mb-0 text-center">Palettenkonto Übersicht</h4>
      </Card.Header>     
        {/* Bootstrap Tabs */}
        <Tabs defaultActiveKey="mitarbeiter" className="mt-3">
          <Tab eventKey="mitarbeiter" title="Mitarbeiter">
            {renderTable(userSummaries, userTotals)}
          </Tab>
          <Tab eventKey="touren" title="Touren">
            {renderTable(tourSummaries, tourTotals)}
          </Tab>
        </Tabs>
      </Card>
    </Container>
  );
};

export default PalettenKonto;
