import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck,faSignal  } from "@fortawesome/free-solid-svg-icons";

import "../../../styles/maptooltip.css"; 

const UserMarker = ({ uid, lat, lng, data,movement,signal }) => {
  console.log("movement->",movement,"Signal->",signal)
  const [showInfo, setShowInfo] = useState(false);
  const truckbounce = <FontAwesomeIcon icon={faTruck} bounce />;
  const truck = <FontAwesomeIcon icon={faTruck} />;  

  const handleClick = (e) => {
    e?.preventDefault();
    setShowInfo(!showInfo);
  };

  const renderSignalBars = (signal) => {
    return <FontAwesomeIcon icon={faSignal} style={{ opacity: (signal + 1) / 5 }} />;
  };

  const renderSignalIcon = (signal) => {
    switch (signal) {
      case 0: return <>🔴</> ;
      case 1: return <>🟠 </>;
      case 2: return <>🟡 </>;
      case 3: return <>🟢 </>;
      case 4: return <>🟢🔵 </>;
      default: return "N/A";
    }
  };

  return (
    <div className="marker" onClick={handleClick} style={{ position: "absolute", zIndex: 1 }}>
      <span className="orange">
        <b>
          <div>{data?.Tour || "Unbekannter Nutzer"}</div>
          <div className="text-center">{movement ? truckbounce : truck}</div>
        </b>
      </span>
      {showInfo && (
        <div className="info-box">
          <button className="close-btn" onClick={() => setShowInfo(false)}>×</button>
          <h4>User-Details</h4>
          <p><strong>Tour:</strong> {data?.Tour || "N/A"}</p>
          <p><strong>Kennzeichen:</strong> {data?.Fahrzeug?.Kennzeichen || "N/A"}</p>         
          <p><strong>Empfang:</strong> {renderSignalIcon(signal)}</p>
          <p><strong>Status:</strong> {movement ? "In Bewegung" : "Stehend"}</p>
        </div>
      )}
    </div>
  );
};

export default UserMarker;
