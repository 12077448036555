import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Table, Container } from "react-bootstrap";
import { ref, getDatabase, onValue, remove, update } from "firebase/database";
import { confirmAlert } from "react-confirm-alert";
import { Cube } from "react-preloaders2";
import "bootstrap-icons/font/bootstrap-icons.css";

export const Notice = ({ FID, UID, CurrentUser }) => {
  const navigate = useNavigate();
  const [dbNotice, setdbNotice] = useState([]);
  const db = getDatabase();
  const [isLoading, setisLoading] = useState(true);

  const actualUID = UID || CurrentUser?.uid;
  
  useEffect(() => {
    if (!CurrentUser) {
      navigate("/login", { replace: true });
      return;
    }

    if (!FID || !actualUID) {
      console.error("Fehlende FID oder UID:", { FID, UID });
      return;
    }

    const Noticeref = ref(db, `notice/${FID}/${actualUID}`);
    setisLoading(true);

    // 🚀 Echtzeit-Listener für Benachrichtigungen
    const unsubscribe = onValue(Noticeref, (snapshot) => {
      if (!snapshot.exists()) {
        setdbNotice([]);
        setisLoading(false);
        return;
      }

      const response = [];
      snapshot.forEach((child) => {
        const data = child.val();
        if (!data) return; // Falls `null` oder `undefined`, überspringen

        response.push({
          id: child.key,
          gelesen: data.gelesen || false,
          message: data.message || "",
          ref: data.ref || "",
          timestamp: data.timestamp || new Date().toISOString(), // 🆕 Standardwert setzen
          type: data.type || "info",
        });
      });

     // console.log("Geladene Benachrichtigungen:", response);
      setdbNotice(response);
      setisLoading(false);
    });

    return () => unsubscribe();
  }, [FID, UID, CurrentUser, db, navigate, actualUID]);

  const handlereadNotice = (nachricht) => {
    try {
      confirmAlert({
        customUI: ({ onClose }) => (
          <div className="custom-ui">
            <h4>Benachrichtigung</h4>
            <p>{nachricht.message}</p>
            <div className="d-flex justify-content-evenly">
              <Button
                variant="outline-primary"
                onClick={() => {
                  const updates = {};
                  updates[`/notice/${FID}/${actualUID}/${nachricht.id}/new`] = false;
                  update(ref(db), updates);
                  onClose();
                }}
              >
                OK
              </Button>
              <Button
                variant="outline-danger"
                onClick={() => {
                  handleDeleteNotice(nachricht);
                  onClose();
                }}
              >
                Löschen
              </Button>
            </div>
          </div>
        ),
      });
    } catch (error) {
      console.error("Fehler beim Lesen der Nachricht:", error);
    }
  };

  const handleDeleteNotice = (nachricht) => {
    try {
      const removes = ref(db, `/notice/${FID}/${actualUID}/${nachricht.id}`);
      remove(removes);
    } catch (error) {
      console.error("Fehler beim Löschen der Nachricht:", error);
    }
  };

  return (
    <Container>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>#</th>
            <th>Datum / Zeit</th>
            <th>Nachricht</th>
            <th>Löschen</th>
          </tr>
        </thead>
        <tbody>
          {dbNotice.length === 0 ? (
            <tr>
              <td colSpan={4} align="center" style={{ color: "blue" }}>
                Keine Nachricht vorhanden
              </td>
            </tr>
          ) : (
            dbNotice.map((nachricht, key) => (
              <tr key={nachricht.id}>
                <td>{key + 1}</td>
                <td>
                  {new Date(nachricht.timestamp).toLocaleDateString("de-DE", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })}
                </td>
                <td>
                  <i
                    className={`bi ${nachricht.new ? "bi-envelope-fill" : "bi-envelope-open-fill"}`}
                    onClick={() => handlereadNotice(nachricht)}
                    style={{
                      color: nachricht.new ? "green" : "red",
                      cursor: "pointer",
                    }}
                  ></i>{" "}
                  {nachricht.message}
                </td>
                <td>
                  {!nachricht.new ? (
                    <i
                      className="bi bi-trash-fill"
                      onClick={() => handleDeleteNotice(nachricht)}
                      style={{ color: "red", cursor: "pointer" }}
                    ></i>
                  ) : (
                    "Zum Löschen erst lesen."
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      <Cube customLoading={!isLoading} time={0} background="#40bfb2" />
    </Container>
  );
};
