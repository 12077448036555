import React, { useState, useEffect } from "react";
import { Row, Col, Button, Container, Spinner, Modal } from "react-bootstrap";
import "../../../App.css";
import { useAuth } from "../../../context/authContext";
import BootstrapTable from "react-bootstrap-table-next";
import {
  child,
  orderByKey,
  startAt,
  endAt,
  query,
  ref,
  getDatabase,
  get,
} from "firebase/database";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useNavigate } from "react-router-dom";
import BasicDocument from "./BasicDocument";
import { BlobProvider, pdf } from "@react-pdf/renderer";
import moment from "moment-feiertage";
import "../spesen/custom.css";
import Moment from "moment";
import isEmpty from "lodash.isempty";
import axios from "axios";

registerLocale("de", de);

// **Formatierungsfunktionen**
const DE_DATE_FORMAT = { day: "2-digit", month: "2-digit", year: "numeric" };
const DE_TIME_FORMAT = {
  hour: "2-digit",
  minute: "2-digit",
  timeZone: "Europe/Berlin",
};
const DE_CURRENCY_FORMAT = { style: "currency", currency: "EUR" };

const formatDate = (timestamp) =>
  new Intl.DateTimeFormat("de-DE", DE_DATE_FORMAT).format(timestamp);
const formatTime = (timestamp) =>
  new Intl.DateTimeFormat("de-DE", DE_TIME_FORMAT).format(timestamp);
const numberFormat = (value) =>
  new Intl.NumberFormat("de-DE", DE_CURRENCY_FORMAT).format(value);

const Spesen = () => {
  const { currentUser, isAdmin, FID, UID, loading } = useAuth();
  const [user, setUser] = useState([]);
  const [arbeitstage, setArbeitstage] = useState([]);
  const [loadingArbeitstage, setLoadingArbeitstage] = useState(true);
  const [loadingUser, setLoadingUser] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [currentEvent, setCurrentEvent] = useState(null);
  const db = getDatabase();
  const navigate = useNavigate();
  let lastId = 0;

  function GesamtArbeitzeitFormatter(zeiticolumn, colIndex, { text }) {
    return milltoHHMMSS(text);
  }
  const SpesenFormat = (value) => {
    return (
      <div className={value === 0 ? "dot1" : ""}>
        {" "}
        {new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
        }).format(value)}{" "}
      </div>
    );
  };

  const ModalEntered = () => {
    if (currentEvent) {
    }
  };

  const milltoHHMMSS = (Mills) => {
    var secends = moment.duration(Mills).seconds();
    var minutes = moment.duration(Mills).minutes();
    var hours = Math.trunc(moment.duration(Mills).asHours());
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    return hours + ":" + minutes;
  };

  function priceFormatter(column, colIndex, { text }) {
    return <>{numberFormat(text)}</>;
  }

  function ArbeitzeitFormatter(zeitinMill) {
    return milltoHHMMSS(zeitinMill);
  }

  const columns = [
    {
      dataField: "id",
      text: "USerID",
      headerAlign: "center",
      hidden: true,
    },
    {
      dataField: "name",
      text: "Mitarbeiter",
      headerAlign: "center",
      hidden: false,
      footer: "",
      footerAttrs: { colSpan: `5` },
      footerStyle: (column, colIndex) => {
        return {
          backgroundColor: "#81c784",
        };
      },
    },
    {
      dataField: "datum",
      text: "Datum",
      formatter: formatDate,
      headerAlign: "center",
      align: "center",

      footerStyle: (column, colIndex) => {
        return {
          backgroundColor: "#81c784",
        };
      },
    },
    {
      dataField: "beginn",
      text: "Arbeitsbeginn",
      formatter: formatTime,
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "pause",
      text: "Pause",
      headerAlign: "center",
      align: "center",
      formatter: milltoHHMMSS,
    },
    {
      dataField: "feierabend",
      text: "Feierabend",
      formatter: formatTime,
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "stunden",
      text: "Arbeitstunden",
      headerAlign: "center",
      formatter: ArbeitzeitFormatter,
      align: "center",
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
      footerFormatter: GesamtArbeitzeitFormatter,
      footerAlign: "center",
      footerStyle: (column, colIndex) => {
        return {
          backgroundColor: "#81c784",
        };
      },
    },
    {
      dataField: "spesen",
      text: "Spesen",
      formatter: SpesenFormat,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          //  console.log(row);
          if (row.spesen !== 0) return;
          setCurrentEvent(row);
          // alert('Click on Product ID field');
        },
      },
      headerAlign: "center",
      align: "right",
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
      footerFormatter: priceFormatter,
      footerAlign: (column, colIndex) => "right",
      footerStyle: (column, colIndex) => {
        return {
          backgroundColor: "#81c784",
        };
      },
    },
    {
      dataField: "tagesabschluss",
      text: "Tagesabschluss",
      headerAlign: "center",
      align: "center",
      hidden: true,
    },
  ];

  useEffect(() => {
    if (!currentUser) navigate("/login", { replace: true });
  }, [currentUser, navigate]);

  useEffect(() => {
    if (FID) {
      get(child(ref(getDatabase()), `users/${FID}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            let resual = [];
            const data = snapshot.val();
            const unsort = Object.keys(data).map((k) => ({
              id: k,
              ...data[k],
            }));
            resual = isAdmin
              ? unsort.filter((user) => user.isDelete === false)
              : unsort.filter((user) => user.id === UID);
            // console.log("user->",resual);
            setUser(resual);
            setLoadingUser(false);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [FID, UID, isAdmin, loading]);

  useEffect(() => {
    if (!loadingUser) {
      getData(
        String(moment(startDate).startOf("month").valueOf()),
        String(moment(startDate).endOf("month").valueOf())
      );
    }
  }, [user, startDate, loadingUser]);

  const handleChange = (date) => {
    setStartDate(date);
    getData(
      String(moment(date).startOf("month").valueOf()),
      String(moment(date).endOf("month").valueOf())
    );
  };

  const getData = async (start, ende) => {
    setLoadingArbeitstage(true);

    try {
      const queryConstraints = [orderByKey(), startAt(start), endAt(ende)];
      const data = await get(
        query(ref(db, "dailybusiness/" + FID), ...queryConstraints)
      );

      if (data.exists()) {
        const fullData = data.val();
        const anwesenheitData = {};
      
        Object.keys(fullData).forEach((key) => {
          if (fullData[key].anwesenheit) {
            anwesenheitData[key] = fullData[key].anwesenheit;
          }
        });
      
        let spesenData = {};
      
        if (isAdmin) {
          Object.entries(user).forEach(([key, value]) => {
            const userSpesen = getSpesenDataforMitarbeiter(anwesenheitData, value.id);
            if (userSpesen !== null && value.isSpesen) {
              spesenData[value.id] = userSpesen;
            }
          });
        } else {
          const userSpesen = getSpesenDataforMitarbeiter(anwesenheitData, UID);
          spesenData[UID] = userSpesen;
        }
      
        setArbeitstage(spesenData);
      } else {
        console.log("Keine Daten gefunden.");
        setArbeitstage({}); // 🔹 **Stelle sicher, dass es ein leeres Objekt ist, nicht undefined!**
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingArbeitstage(false);
    }
  };

  function getSpesenDataforMitarbeiter(data, UserID) {
    let mitarbeiterSpesen = [];
    // console.log("UserID->",UserID);

    Object.entries(data).forEach(([Datum, Mitarbeiterliste]) => {
      /* const entries = Object.entries(Mitarbeiterliste).find(        
        (item) => item === UserID
      );*/

      const entries = Object.entries(Mitarbeiterliste).find(
        ([key, value]) => key === UserID
      );
      //console.log("entries->",entries);

      const usernow = user.find((a) => a.id === UserID);

      if (entries) {
        if (entries[1].tagesabschluss) {
          lastId++;

          let millsek = entries[1].end - entries[1].beginn;
          const StundenFormat = Moment(millsek - 60 * 60 * 1000).hour();
          let pause;
          let spesenbetrag = 0;
          let infoContent = "";
          if (StundenFormat > 8) {
            pause = 2700000;
          } else if (StundenFormat >= 6) {
            pause = 1800000;
          } else if (StundenFormat <= 5) {
            pause = 0;
          }
          if (entries[1].spesen) {
            spesenbetrag = 14;
          } else {
            if (entries[1].tagesabschluss) {
              infoContent = (
                <>
                  <h5>Zu früh Feierabend gemacht</h5>
                  <div>
                    Am {formatDate(entries[1].beginn)} haben Sie von{" "}
                    {formatTime(entries[1].beginn)} bis{" "}
                    {formatTime(entries[1].end)} gearbeitet.
                  </div>
                  <div>
                    Nach Abzug der {ArbeitzeitFormatter(pause)} Pause ergibt
                    sich eine effektive Arbeitszeit von{" "}
                    {ArbeitzeitFormatter(millsek - pause)}{" "}
                  </div>
                  <div>
                    Da die erforderliche Mindestarbeitszeit von 8:00 Stunden
                    nicht erreicht wurde, entfällt die Spesenauszahlung für
                    diesen Tag.
                  </div>
                </>
              );
            } else {
              infoContent = (
                <>
                  <h5>Keinen Tagesabschluss gemacht.</h5>
                  <div>
                    Sie haben am {formatDate(entries[1].beginn)} keinen
                    Tagesabschluss gemacht.
                  </div>
                  <div>
                    Dadurch haben wir nicht die möglichkeit festzustellen wann
                    Sie wirklich Feierabend gemacht haben.
                  </div>
                </>
              );
            }
          }
          millsek = millsek - pause;

          mitarbeiterSpesen.push({
            key: lastId,
            id: UserID,
            name: usernow.name,
            datum: entries[1].beginn,
            beginn: entries[1].beginn,
            pause: pause,
            feierabend: entries[1].end,
            stunden: millsek,
            spesen: spesenbetrag,
            tagesabschluss: entries[1].tagesabschluss,
            infoContent: infoContent,
          });
        } else {
          if (typeof entries[1]["places"] !== "undefined") {
            let arr = entries[1]["places"]["Feierabend Zone"] || entries[1]["places"]["Lade-Entlade Punkt"];
          
            if (arr) {
              let lastEnter = Object.entries(arr)
                .filter(([times, value]) => value === "Enter")
                .pop();
          
              if (!lastEnter) {
                // Falls kein "Enter" existiert, suche nach "Leaving"
                lastEnter = Object.entries(arr)
                  .filter(([times, value]) => value === "Leaving")
                  .pop();
              }
          
              if (lastEnter) {
                lastId++;
                let millsek = lastEnter[0] - entries[1].beginn;
                const StundenFormat = Moment(millsek - 60 * 60 * 1000).hour();
                let pause = 0;
          
                if (StundenFormat > 8) {
                  pause = 2700000;
                } else if (StundenFormat >= 6) {
                  pause = 1800000;
                }
          
                let spesenbetrag = 0;
                let infoContent = (
                  <>
                    <h5>Keinen Tagesabschluss gemacht.</h5>
                    <div>
                      Sie haben am {formatDate(entries[1].beginn)} keinen Tagesabschluss gemacht.
                    </div>
                    <div>
                      Dadurch haben wir nicht die Möglichkeit, festzustellen, wann Sie wirklich Feierabend gemacht haben.
                    </div>
                  </>
                );
          
                mitarbeiterSpesen.push({
                  key: lastId,
                  id: UserID,
                  name: usernow.name,
                  datum: entries[1].beginn,
                  beginn: entries[1].beginn,
                  pause: pause,
                  feierabend: lastEnter[0], // Letzter "Leaving" oder "Enter"-Wert
                  stunden: millsek,
                  spesen: spesenbetrag,
                  tagesabschluss: entries[1].tagesabschluss,
                  infoContent: infoContent,
                });
              }
            }
          }
          
        }
      }
    });

    if (mitarbeiterSpesen.length !== 0) return mitarbeiterSpesen;
    return null;
  }

  const sendEmailWithPDF = async () => {
    try {
      // 🔹 PDF-Datei direkt in React generieren
      const blob = await pdf(
        <BasicDocument data={arbeitstage} userinfo={user} />
      ).toBlob();

      // 🔹 PDF als FormData senden
      const formData = new FormData();
      formData.append(
        "pdf",
        blob,
        `Spesenabrechnung-${moment(startDate).format("YYYYMM")}.pdf`
      );
      formData.append("month", moment(startDate).format("YYYYMM"));

      const response = await axios.post(
        "http://localhost:5000/send-email",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.status === 200) {
        alert("📧 E-Mail erfolgreich gesendet!");
      } else {
        alert("❌ Fehler beim Senden der E-Mail.");
      }
    } catch (error) {
      console.error("Fehler beim E-Mail-Versand:", error);
      alert("❌ Fehler beim Senden der E-Mail.");
    }
  };

  const ConsoleLog = ({ children, key, user }) => {
    console.log("Arbeitstage->", children, "key->", key, "user->", user);
    return false;
  };

  return (
    <Container>
      <Row className="border-bottom pb-2 mt-4">
        <Col md="auto">
          <h4>Spesen für</h4>
        </Col>
        <Col md="auto">
          <DatePicker
            selected={startDate}
            onChange={handleChange}
            locale="de"
            dateFormat="MMMM yyyy"
            showMonthYearPicker
          />
        </Col>
        <Col md="auto">
          {!loadingArbeitstage && !isEmpty(arbeitstage) && (
            <>
              <div className="d-flex align-items-center">
                {/* PDF herunterladen */}
                <BlobProvider
                  document={
                    <BasicDocument data={arbeitstage} userinfo={user} />
                  }
                >
                  {({ url }) =>
                    url ? (
                      <a
                        href={url}
                        download={`Spesenabrechnung-${moment(startDate).format(
                          "YYYYMM"
                        )}.pdf`}
                      >
                        <button className="btn btn-success me-3">
                          PDF herunterladen
                        </button>
                      </a>
                    ) : (
                      <span>Generiere PDF...</span>
                    )
                  }
                </BlobProvider>

                {/* PDF anzeigen */}
                <BlobProvider
                  document={
                    <BasicDocument data={arbeitstage} userinfo={user} />
                  }
                >
                  {({ url }) => (
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      <button className="btn btn-primary me-3">
                        PDF anzeigen
                      </button>
                    </a>
                  )}
                </BlobProvider>

                {/* E-Mail senden (nur für Admin) */}
                {isAdmin && (
                  <button
                    className="btn btn-warning"
                    onClick={sendEmailWithPDF}
                  >
                    E-Mail senden
                  </button>
                )}
              </div>
            </>
          )}
        </Col>
      </Row>

      {loadingArbeitstage ? (
        <div className="text-center mt-3">
          <Spinner animation="border" variant="primary" />
          <p>Spesen werden geladen...</p>
        </div>
      ) : Object.keys(arbeitstage).length > 0 ? (
        <Scrollbars autoHeight autoHeightMax="calc(100vh)">
          {arbeitstage && Object.keys(arbeitstage).length > 0 ? (
            Object.entries(arbeitstage).map(([userId, spesenArray], index) => (
              <Row className={index > 0 ? "mt-3" : ""} key={userId}>
                <h3>{spesenArray[0]?.name || "Unbekannter Mitarbeiter"}</h3>
                <BootstrapTable
                  striped
                  bootstrap4
                  keyField="key"
                  data={spesenArray} // Direkt als Array übergeben
                  columns={columns}
                />
              </Row>
            ))
          ) : (
            <p className="text-center mt-3">Keine Spesen gefunden.</p>
          )}
        </Scrollbars>
      ) : (
        <p className="text-center mt-3">Keine Spesen gefunden.</p>
      )}
      <Modal
        show={currentEvent}
        onHide={() => setCurrentEvent(null)}
        onEntering={() => ModalEntered()}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        {currentEvent && (
          <div>
            <Modal.Header closeButton>
              <Modal.Title>Warum habe ich keine Spesen bekommen?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="mb-3 ">{currentEvent.infoContent}</Row>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="outline-secondary"
                disabled={false}
                className="shadow"
                onClick={() => {
                  setCurrentEvent(null);
                }}
              >
                OK Verstanden
              </Button>
            </Modal.Footer>
          </div>
        )}
      </Modal>
    </Container>
  );
};

export default Spesen;
