import React, { useContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import { get, child, ref, getDatabase,onValue } from "firebase/database";
import toast, { Toaster } from "react-hot-toast";


const AuthContext = React.createContext();

export function AuthProvider({ children }) {
 
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState();
  const [currentFirma, setCurrentFirma] = useState();
  const [currentUserDetail, setcurrentUserDetail] = useState();
  const [isAdmin, setisAdmin] = useState(false);
  const [FID, SetFID] = useState();
  const [UID, SetUID] = useState();
  const [currentMitarbeiter, setmitarbeiter] = useState([]);
  const dbRef = ref(getDatabase());
  const db = getDatabase();
  

  function singup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
    
  }

  const login = (email, password) => {
    auth.signInWithEmailAndPassword(email, password);
    return isAdmin;
  };

  const logout = () => {
   
     auth.signOut();
     toast.success(currentUser.displayName + '\nwurde erfolgreich abgemeldet!',{
      style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
    });
    setCurrentUser(null);
    setCurrentFirma(null);
    setcurrentUserDetail(null);
    setmitarbeiter(null);
    setisAdmin(false);
    return
  };

  

  const resetPassword = async (email) => {
    return await auth.sendPasswordResetEmail(email);
  };

  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      
      if (user) {
        setCurrentUser(user);
        const kaleref = ref(db, "users/" + user.photoURL);
        get(child(dbRef, "users/" + user.photoURL + "/" + user.uid))
            .then((snapshot) => {
              if (snapshot.exists()) {
                setcurrentUserDetail(snapshot.val());
                SetUID(user.uid);
                const isAdmin = snapshot.val().rollen.filter(rolle => 
                  rolle.label.toLowerCase().includes('admin')
                )
                if(isAdmin.length !== 0 ){
                 
                  setisAdmin(true)
                
                  
               
                }else{
                 
                  setisAdmin(false)
                
                }
                      
  
              } else {
                console.log("No Users data available");
              }
            })
            .catch((error) => {
              console.error(error);
            });
  
       
          get(child(dbRef, "firmen/" + user.photoURL))
            .then((snapshot) => {
              if (snapshot.exists()) {
                setCurrentFirma(snapshot.val());             
              } else {
                console.log("No data available");
              }
            })
            .catch((error) => {
              console.error(error);
            });
  
            onValue(kaleref, (snapshot) => {
              if (snapshot.exists()) {
                setmitarbeiter(snapshot.val())
              }         
            })
  
  
  
        SetFID(user.photoURL)
        if (user.displayName !== null) {
         
          toast.success(user.displayName + "\nwurde erfolgreich angemeldet!",{
            style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
          });
  
  
  
        }else{
            toast.success("Ihre Firma wurde erfolgreich registriert!",{
                style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                  },
              });
           // auth.signOut();
           
        }
      }
      setLoading(false);
    });
    return unsubscribe
  }, [db, dbRef]);

  useEffect(() => {

  },[])












  const value = {
    currentUser,   
    currentUserDetail,
    isAdmin,
    currentFirma,
    loading,
    FID,
    UID,
    currentMitarbeiter,
    singup,
    login,
    logout,
    resetPassword,
  };

//console.log("unsubscribe=>",unsubscribe)

  return (

    <AuthContext.Provider value={value}>
      <Toaster 
        position="top-center"
        reverseOrder={true}>
      </Toaster>
      {children}
    </AuthContext.Provider>

  );
}

export function useAuth() {
 return useContext(AuthContext) 
}
